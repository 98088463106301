import { Injectable } from '@angular/core';
import { ProfileService } from '../APIs/profile.service';


declare global {
  interface Window { dataLayer: any[]; }
}


@Injectable({
    providedIn: 'root',
  })

  
  export class GA4Service {
    userId:any;
    market:any;
    userType:any;
    country:any;
    storeId:any;
    dealerName:any;

    constructor( private profileService: ProfileService) {} 

    loginPush(){
        this.profileService.getProfile().subscribe((data: any) => {
            this.userId = data.userId;
            sessionStorage.setItem("userId", data.userId)
            this.market = data.marketCode;
            sessionStorage.setItem("marketCode", data.marketCode)
            this.userType = data.userType;
            sessionStorage.setItem("userType", data.userType)
            this.country = data.countryName;
            sessionStorage.setItem("countryName", data.countryName) 
            this.storeId = data.storeId; 
            sessionStorage.setItem("storeId", data.storeId)
            this.dealerName = data.dealerName;  
            sessionStorage.setItem("dealerName", data.dealerName)
            window.dataLayer.push({ 
                'event': 'login',
                'user_id': this.userId, 
                'market': this.market,
                'user_type': this.userType,
                'country': this.country,
                'store_id': this.storeId,
                'dealer_name': this.dealerName,
              });
          });       
    }

    refreshPush(){
        let chechId = sessionStorage.getItem('userId');
        if(chechId){
            window.dataLayer.push({            
                'user_id': sessionStorage.getItem("userId"),
                'market': sessionStorage.getItem("marketCode"),
                'user_type': sessionStorage.getItem("userType"),
                'country': sessionStorage.getItem("countryName"),
                'store_id': sessionStorage.getItem("storeId"),
                'dealer_name': sessionStorage.getItem("dealerName"),
              });
        }else{
            this.profileService.getProfile().subscribe((data: any) => {
                this.userId = data.userId;
                sessionStorage.setItem("userId", data.userId)
                this.market = data.marketCode;
                sessionStorage.setItem("marketCode", data.marketCode)
                this.userType = data.userType;
                sessionStorage.setItem("userType", data.userType)
                this.country = data.countryName;
                sessionStorage.setItem("countryName", data.countryName)
                this.storeId = data.storeId;
                sessionStorage.setItem("storeId", data.storeId)
                this.dealerName = data.dealerName;  
                sessionStorage.setItem("dealerName", data.dealerName)
                window.dataLayer.push({
                    'user_id': this.userId,
                    'market': this.market,
                    'user_type': this.userType,
                    'country': this.country,
                    'store_id': this.storeId,
                    'dealer_name': this.dealerName,
                  });
              });
        }
    }

    homeCategoryClickPush(categoryName: any){
        window.dataLayer.push({
            'event': 'category_selection',
            'category': categoryName,
            });
    }

    categoryChangePush(categoryName: any){
        window.dataLayer.push({
            'event': 'category_change',
            'category': categoryName,
        });
    }

    viewSectionPush(sectionName: any){
        window.dataLayer.push({
            'event': 'view_section',
            'section_name': sectionName,
            });
    }

    completeSectionPush(sectionName: any){
        window.dataLayer.push({
            'event': 'section_complete',
            'section_name': sectionName,
            });
    }

    sectionModalOpenPush(sectionName: any){
        window.dataLayer.push({
            'event': 'section_modal_open',
            'section_name': sectionName,
            'modal_open_count': 1,
        });
    }

    siteSearchResultPush(searchQuery: any, searchTotal: number){
        window.dataLayer.push({
            'event': 'site_search_results',
            'search_query': searchQuery,
            'search_result_total': searchTotal,
          });
    }

    resourceSelectedPush(categoryName: any, resourceTitle: any, resourceType: any, currentPage: any){
        window.dataLayer.push({
            'event': 'resource_selected',
            'category': categoryName,
            'resource_name': resourceTitle,
            'resource_type': resourceType,
            'selection_type': currentPage,
          });
    }

    resourceSelectedSearchPush(categoryName: any, resourceTitle: any, resourceType: any, currentPage: any, searchQuery: any, searchTotal: number){
        window.dataLayer.push({
            'event': 'search_resource_selected',
            'category': categoryName,
            'resource_name': resourceTitle,
            'resource_type': resourceType,
            'selection_type': currentPage,
            'search_query': searchQuery,
            'search_result_total': searchTotal,
          });
    }

    resourceEngagement(engagementType: any, resourceName: any){
        window.dataLayer.push({
            'event': 'resource_engagement',
            'engagement_type': engagementType,
            'resource_name': resourceName,
        });
    }

    resourceEngagementKeybord(engagementType: any, resourceName: any){
        window.dataLayer.push({
            'event': 'resource_engagement',
            'engagement_type': engagementType,
            'resource_name': resourceName,
        });
    }

    resourceEngagementRating(engagementType: any, resourceName: any, rating: any){
        window.dataLayer.push({
            'event': 'rate_resource_engagement',
            'engagement_type': engagementType,
            'resource_name': resourceName,
            'rating': rating,
        });
    }

    refinePush(resourceType: any){
        window.dataLayer.push({
            'event': 'refine',
            'resource_type': resourceType,
        });
    }

    downloadPush(){
        window.dataLayer.push({
            'event': 'download_worksheet',
        });
    }

    footerClickPush(linkName: any, linkUrl: any){
        window.dataLayer.push({
            'event': 'footer_click',
            'link_text': linkName,
            'link_url': linkUrl
        });
    }

    onboardClickPush(step: any){
        window.dataLayer.push({
            'event': 'onboard_click',
            'onboard_step': step,
        });
    }

    signOutPush(){
        window.dataLayer.push({
            'event': 'sign_out'
        });
    }
  }