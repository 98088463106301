import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { CardService } from '../../APIs/card.service';
import { SearchService } from '../../APIs/search.service'; 
import { SharedService } from '../../shared.service';
import { Paginator } from '../../paginator';  
import { RouteService } from 'src/app/services/route.service';
import { BookmarkService } from '../../APIs/bookmarked.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { MatSelectChange } from '@angular/material/select';
import { ToastService } from '../../../app/shared.service';
import { Subscription } from 'rxjs';


interface ResourceForBookmarkIcon {
  resourceId: number;
  isFavorite: boolean;
  contentTypeId?: number;
}

@Component({
  selector: 'app-support-guide',
  templateUrl: './support-guide.component.html',
  styleUrls: ['./support-guide.component.scss']
})
export class SupportGuideComponent implements OnInit, OnDestroy {
  
  initialCards: any[] = [];
  resContentTypes: any[] = [];
  resContentTypesForHTML: any[] = [];
  resContentType: any = null;
  allBlock: any[] = [];
  take: number = 0;
  skip: number = 0;
  total: number = 0;
  pagesAmount: number = 0;
  pagesStep: number = 0;
  pagesList: any[] = [];
  activePage: number = 1;
  establishBlock: any[] = [];
  expandBlock: any[] = [];
  optimizeBlock: any[] = [];
  categoryId: any = null;
  searchTerm: string = '';
  searchWord: string = '';
  catIdLocal: any= '0';
  pageNum: number = 0;
  prevPage: number = 0;
  nextPage: number = 0;
  activeCategory: any = "";
  selectedCard: any;
  apiStatus: string = 'pending';
  resourcesTypes: any;
  bannerURL: any;
  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;

  constructor(private cardService: CardService, private searchService: SearchService, private dataService: SharedService, private paginator: Paginator, private routeService: RouteService, private BookmarkService: BookmarkService,
    private tagService: GA4Service, private toastService: ToastService) {}

 

  ngOnInit(): void {   
  const showToastStatus = sessionStorage.getItem("showToastClosed");
  setTimeout(() => {
    if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
    }
  }, 100)

  this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
    this.extraPaddingContent = showToast;
  });

  const mainLogo = document.getElementById("mainLogo");
  if (mainLogo) {
    mainLogo.focus();
  }
  this.catIdLocal = sessionStorage.getItem("Id");
  this.tagService.refreshPush();
  let CatNumber = sessionStorage.getItem("CategoriesNumber");
  if (CatNumber == null){
    this.cardService.getCards().subscribe((data) => {      
      CatNumber = data.total;
      sessionStorage.setItem("CategoriesNumber", data.total);
      this.categoriesWithParam(CatNumber);
    });
  } else {
    this.categoriesWithParam(CatNumber);
  }
   
    

    this.cardService.getResourceContentTypes().subscribe((data) => {      
      const resData = data as { contentTypes: any[] };                  
      this.resContentTypes = resData.contentTypes;      
      this.resContentTypesForHTML =  this.resContentTypes.filter(x => x.languageCode == localStorage.getItem("siteLang"));      
    });
    if (this.catIdLocal > 0) {
    var contTypeId = 38004;  
    this.searchService.getResultsWithParam(null, this.catIdLocal).subscribe((data) => {      
      const searchData = data as { items: any[] };   
      this.allBlock = searchData.items;
      this.paginatorLoader(data);      
      // The rest of your code that depends on this.itemsPerPage
      this.establishBlock = this.allBlock.filter((x)=>x.contentTypeId === 39001);
      this.expandBlock = this.allBlock.filter((x)=>x.contentTypeId === 39002);
      this.optimizeBlock = this.allBlock.filter((x)=>x.contentTypeId === 39003);
      document.getElementById(this.catIdLocal)?.classList.add("active");
      this.categoryId = this.catIdLocal;  
      this.apiStatus = 'success';
    });
    } else {
      this.searchService.getAllResults().subscribe((data) => {
        const searchData = data as { items: any[] };   
        this.allBlock = searchData.items;
        this.paginatorLoader(data);
        this.establishBlock = this.allBlock.filter((x)=>x.contentTypeId === 39001);
        this.expandBlock = this.allBlock.filter((x)=>x.contentTypeId === 39002);
        this.optimizeBlock = this.allBlock.filter((x)=>x.contentTypeId === 39003);
        document.getElementById("page1")?.classList.add("active");
        this.apiStatus = 'error';
      });
    }    
  }   
  ngOnDestroy(): void {
    sessionStorage.setItem("Id", "0");
    this.showToastSubscription?.unsubscribe();
  }

  @ViewChild('headerLink') headerLink!: ElementRef;

  handleTabKey(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.headerLink.nativeElement.focus();
    }
  }

  changeItemCategory(event: any, resContentType: any, category: any, cardTitle: any, banerUrl: any){   
    this.categoryId = category;
    var activeEl;
    var resSelector;
    this.bannerURL = banerUrl;
    activeEl = document.getElementsByClassName("sg-left-item active")[0];
    resSelector =  document.getElementsByClassName("sg-selector active")[0];
    resSelector.classList.remove('active');
    this.activeCategory = event.currentTarget.innerText;
    document.getElementsByClassName("sg-selector")[0].classList.add('active');
    this.apiStatus = 'success';
    if (event.target.classList.contains("active")){
      return;
    } else {
      event.target.classList.add('active');
      if (activeEl != undefined){
        activeEl.classList.remove('active');
      }
      this.apiStatus = 'error';      
    }      

    this.searchService.getResultsWithParam(resContentType, this.categoryId).subscribe((data) => {
      const searchData = data as { items: any[] };   
      this.allBlock = searchData.items;
      this.paginatorLoader(data);
      var activeNum = document.querySelectorAll(".custom-page.active");
      activeNum[0]?.classList.remove("active"); 
      
      this.activePage = 1
      document.getElementById("arrowPrev")?.classList.add("hidden");
      document.getElementById("arrowNext")?.classList.remove("hidden");

      this.establishBlock = this.allBlock.filter((x)=>x.contentTypeId === 39001);
      this.expandBlock = this.allBlock.filter((x)=>x.contentTypeId === 39002);
      this.optimizeBlock = this.allBlock.filter((x)=>x.contentTypeId === 39003);
      
    });
    this.tagService.categoryChangePush(cardTitle);
  }


  changeItemCategoryMobile(resContentType: any, category: any, cardTitle: any, banerUrl: any){   
    this.categoryId = category;
    this.bannerURL = banerUrl;
    this.searchService.getResultsWithParam(resContentType, this.categoryId).subscribe((data) => {
      const searchData = data as { items: any[] };   
      this.allBlock = searchData.items;
      this.paginatorLoader(data);
      var activeNum = document.querySelectorAll(".custom-page.active");
       activeNum[0].classList.remove("active"); 

      this.activePage = 1
      document.getElementById("arrowPrev")?.classList.add("hidden");
      document.getElementById("arrowNext")?.classList.remove("hidden");

      this.establishBlock = this.allBlock.filter((x)=>x.contentTypeId === 39001);
      this.expandBlock = this.allBlock.filter((x)=>x.contentTypeId === 39002);
      this.optimizeBlock = this.allBlock.filter((x)=>x.contentTypeId === 39003);
    });
    this.tagService.categoryChangePush(cardTitle);
  }

  onCategorySelected(event: MatSelectChange) {
    // Handle the selection change event here
    // console.log('Selected category:', event.value);
  }


  changeItem(event: any, resContentType: any, category: any){  
    const searchWord = sessionStorage.getItem('SearchWord');
    if (this.searchTerm.trim() !== '' && this.searchTerm.trim() === searchWord) {
      this.tagService.refinePush(event.srcElement.innerText);
      var activeEl;
      this.resContentType = resContentType;
      activeEl = document.getElementsByClassName("sg-selector active")[0];
      if (event.target.classList.contains("active")){
        return;
      } else {
        event.target.classList.add('active');
        activeEl.classList.remove('active');
      }    
      var activeNum = document.querySelectorAll(".custom-page.active");
      activeNum[0]?.classList.remove("active"); 
      
      
      this.searchService.getResultsWithParam(resContentType, category).subscribe((data) => {
        const searchData = data as { items: any[] };   
        this.allBlock = searchData.items;
        this.paginatorLoader(data);
  
        this.activePage = 1
        document.getElementById("arrowPrev")?.classList.add("hidden");
        document.getElementById("arrowNext")?.classList.remove("hidden");
  
        this.establishBlock = this.allBlock.filter((x)=>x.contentTypeId === 39001);
        this.expandBlock = this.allBlock.filter((x)=>x.contentTypeId === 39002);
        this.optimizeBlock = this.allBlock.filter((x)=>x.contentTypeId === 39003);
        
      });
    } else {
      const lastSearchWord = sessionStorage.getItem('SearchWord');
      this.searchTerm = lastSearchWord || '';

      this.tagService.refinePush(event.srcElement.innerText);
      var activeEl;
      this.resContentType = resContentType;
      activeEl = document.getElementsByClassName("sg-selector active")[0];
      if (event.target.classList.contains("active")){
        return;
      } else {
        event.target.classList.add('active');
        activeEl.classList.remove('active');
      }    
      var activeNum = document.querySelectorAll(".custom-page.active");
      activeNum[0]?.classList.remove("active"); 
      
      
      this.searchService.getResultsWithParam(resContentType, category).subscribe((data) => {
        const searchData = data as { items: any[] };   
        this.allBlock = searchData.items;
        this.paginatorLoader(data);
  
        this.activePage = 1
        document.getElementById("arrowPrev")?.classList.add("hidden");
        document.getElementById("arrowNext")?.classList.remove("hidden");
  
        this.establishBlock = this.allBlock.filter((x)=>x.contentTypeId === 39001);
        this.expandBlock = this.allBlock.filter((x)=>x.contentTypeId === 39002);
        this.optimizeBlock = this.allBlock.filter((x)=>x.contentTypeId === 39003);
        
      });
    }
   
  }

  categoriesWithParam(CatNumber: any){
    this.cardService.getCardsWithParam(CatNumber).subscribe((data) => {
      const typedData = data as { items: any[] }; 
      for(let i = 0; i < typedData.items.length; i++){ 
        if (typedData.items[i].id == this.catIdLocal){
          this.bannerURL = typedData.items[i].bannerUrl;
        }              
      }       
      this.initialCards = typedData.items;
    });
  }

  searchItem(searchWord: string ) {   
    this.dataService.changeSearchData(searchWord);
    sessionStorage.setItem("SearchWord", searchWord);         
  }

  updatePagesList() {
    const maxPagesToShow = 5;
    const middlePage = Math.ceil(maxPagesToShow / 2);
  
    if (this.pagesAmount <= maxPagesToShow) {
      this.pagesList = Array.from({ length: this.pagesAmount }, (_, i) => i + 1);
    } else {
      if (this.activePage <= middlePage) {
        // Display the first maxPagesToShow pages and add the last page with ...
        this.pagesList = Array.from({ length: maxPagesToShow }, (_, i) => i + 1);
        this.pagesList.push('...');
        this.pagesList.push(this.pagesAmount);
      } else if (this.activePage > this.pagesAmount - middlePage) {
        // Display the last maxPagesToShow pages, including the first page with ...
        this.pagesList = [1, '...'];
        this.pagesList = this.pagesList.concat(
          Array.from({ length: maxPagesToShow - 2 }, (_, i) => this.pagesAmount - maxPagesToShow + i + 3)
        );
      } 
      else {
        // Display the current page and the pages around it with the first and last page with ...
        this.pagesList = [1, '...'];
        this.pagesList = this.pagesList.concat(
          Array.from({ length: maxPagesToShow - 2 }, (_, i) => this.activePage - middlePage + i + 2)
        );
        this.pagesList.push('...');
        this.pagesList.push(this.pagesAmount);
      }
    }
  }

  changePage(pageNum: number, arrowButton: any){
    var activeNum = document.querySelectorAll(".custom-page.active");
    if (activeNum && activeNum.length > 0) {
      activeNum[0].classList.remove("active");
    }

    if (pageNum > 0){
      this.activePage = pageNum;      
    } else if (pageNum == 0 && arrowButton == true){
      this.activePage = this.activePage - 1;
    } else if (pageNum == 0 && arrowButton == false){
      this.activePage = this.activePage + 1;
    }

    document.getElementById("page" + this.activePage)?.classList.add("active");
    if (this.activePage == 1){
      document.getElementById("arrowPrev")?.classList.add("hidden");
      document.getElementById("arrowNext")?.classList.remove("hidden");
    } else if (this.activePage == this.pagesAmount){
      document.getElementById("arrowPrev")?.classList.remove("hidden");
      document.getElementById("arrowNext")?.classList.add("hidden");
    } else {
      document.getElementById("arrowPrev")?.classList.remove("hidden");
      document.getElementById("arrowNext")?.classList.remove("hidden");
    }
    
    this.skip = 0;
    // if arrowButton is true - we clicked Previous Page, is false - we clicked Next Page, if null we clicked on page number
    
    this.take = 10;
    this.skip = this.activePage - 1;    
    if (this.resContentType == undefined){
      this.resContentType = null;
    }

    if (this.categoryId == undefined){
      this.categoryId = null;
    }
    this.searchService.getResultsFull(this.resContentType, this.categoryId, this.take, this.skip).subscribe((data) => {
      const searchData = data as { items: any[] };   
      this.allBlock = searchData.items;

      this.establishBlock = this.allBlock.filter((x)=>x.contentTypeId === 39001);
      this.expandBlock = this.allBlock.filter((x)=>x.contentTypeId === 39002);
      this.optimizeBlock = this.allBlock.filter((x)=>x.contentTypeId === 39003);
      
    });

    this.updatePagesList();
  }

  paginatorLoader(data: any){
    this.pagesList = [];
    this.pagesStep = 0;
    this.take = parseInt(data.take);
    this.skip = parseInt(data.skip);
    this.total = parseInt(data.total);
    this.activePage = 1;
    this.paginator.createPaginationParameters(this.take, this.skip, this.total).subscribe((parameters) => {
      this.pagesAmount = parameters[0];
      for(let i = 0; i < this.pagesAmount; i++){   
          this.pagesStep = this.pagesStep + 1;
          this.pagesList.push(this.pagesStep);              
        }        
    });
    document.getElementById("arrowPrev")?.classList.add("hidden");
    setTimeout("document.getElementById('page1')?.classList.add('active')", 1000);
   
    this.updatePagesList();

  }

  onBookmarkIconClick(resource: ResourceForBookmarkIcon, title: any) {    
    const resourceId = resource.resourceId;
  
    this.BookmarkService.postBookmark(resourceId).subscribe(
      (response) => {
        if (!resource.isFavorite){
          this.tagService.resourceEngagement('bookmark', title);
        } else {
          this.tagService.resourceEngagement('remove bookmark', title);
        }
        resource.isFavorite = !resource.isFavorite;
  
        if (resource.contentTypeId === 39001) {
          this.updateBlock(this.establishBlock, resource);
        } else if (resource.contentTypeId === 39002) {
          this.updateBlock(this.expandBlock, resource);
        } else if (resource.contentTypeId === 39003) {
          this.updateBlock(this.optimizeBlock, resource);
        }
  
        this.updateBlock(this.allBlock, resource);
      },
      (error) => {
      }
    );
  }
  
  private updateBlock(block: any[], updatedResource: any) {
    const index = block.findIndex(item => item.resourceId === updatedResource.resourceId);
  
    if (index !== -1) {
      block[index].isFavorite = updatedResource.isFavorite;
    }
  }
  

  showTooltip(id:any){
    document.getElementById(id.id)?.setAttribute("style", "display: block;");;
  }

  hideTooltip(id:any){
    document.getElementById(id.id)?.setAttribute("style", "display: none;");;
  }

  resourceGA4Push(categoryName: any, resourceTitle: any, resourceType: any, currentPage: any){
    this.tagService.resourceSelectedPush(categoryName, resourceTitle, resourceType, currentPage);
  }

}
