<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
    <nav class="navigationWithPositionAbsolute">
        <a class="niceLineForFooterNav" [routerLink]="['/']">{{'GetSupport.Home' | translate}} </a>
        <span> > </span>
        <span> {{'GetSupport.GetSupport' | translate}} </span>
    </nav>

    <div class="contentHolder">
        <h2 class="mb-30">{{'GetSupport.GetSupport' | translate}}</h2>
        <p>{{'GetSupport.GetSupportText' | translate}} <a href="mailto:FGEgo@learning-help.com">{{'GetSupport.GetSupportMail' | translate}}</a>.</p>
    </div>
</div>
