<mat-drawer-container autosize="">
    <mat-drawer #drawer opened="false" mode="push" position="end">
        <mat-nav-list>
            <!-- <mat-list-item> -->
                <section class="wrapSlideMenuHeader">
                    <p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.JumpToSection" | translate}}</p>
                    <a id="dnload-wkst" class="makeCertainSize" mat-raised-button href="{{'SharedItems.WorkSheetUrl' | translate}}" target="_blank" (click)="downloadGA4Push()">{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.ViewWorksheet" | translate}}</a>
                </section>
                <section class="wrapSlideMenuBody">
                    <div class="headerDivInSlideMenuBody">
                        <h4><b>{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionFirst" | translate}}</b></h4>
                        <p>{{ getCompletedSectionsCount() }} {{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.Of_Eleven_Completed" | translate}}</p>
                    </div>

                    <section class="mainNavSectionInSlideMenuBody">
                    <!-- First Part -->
                        <div class="wrapDotsAndWords">
                            <div class="navigationDotsInSlideMenu">
                                <ng-container *ngFor="let status of sectionStatus; let i = index; let last = last">
                                    <a class="dots" [ngClass]="{ 'outerBorder': status }">
                                        <div class="mini-dot" [ngClass]="{ 'activeDot': status }">
                                            <svg id="icon_-_checkmark_small" data-name="icon - checkmark small" xmlns="http://www.w3.org/2000/svg" width="14.142" height="10.607" viewBox="0 0 14.142 10.607">
                                                <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="13" rx="1" transform="translate(12.728) rotate(45)" fill="#fff"/>
                                                <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="7" rx="1" transform="translate(0 5.657) rotate(-45)" fill="#fff"/>
                                            </svg>  
                                        </div>
                                    </a>
                                    <span *ngIf="!last"></span>
                                </ng-container>
                            </div>
                            <div class="textCloseToDotsInSideMenu">
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="diversity"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionOne" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="gender"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTwo" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="age"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionThree" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="generation"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFour" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="evs"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFive" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="online_sales"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSix" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest_personas"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSeven" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEight" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest_representation"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionNine" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTen" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="last_best_experience"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEleven" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                            </div>
                        </div>
                    </section>
                </section>


                <section class="wrapSlideMenuBody">
                    <div class="headerDivInSlideMenuBody">
                        <h4><b>{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionSecond" | translate}}</b></h4>
                        <p>{{ getCompletedSecondSectionsCount() }} {{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.Of_Six_Completed" | translate}}</p>
                    </div>

                    <section class="mainNavSectionInSlideMenuBody">
                        <div class="wrapDotsAndWords">
                            <div class="navigationDotsInSlideMenu">
                                <ng-container *ngFor="let secondStatus of secondSectionStatus; let i = index; let last = last">
                                    <a class="dots" [ngClass]="{ 'outerBorder': secondStatus || currentSectionIndex === i }">
                                        <div class="mini-dot" [ngClass]="{ 'activeDot': secondStatus }">
                                        <svg id="icon_-_checkmark_small" data-name="icon - checkmark small" xmlns="http://www.w3.org/2000/svg" width="14.142" height="10.607" viewBox="0 0 14.142 10.607">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="13" rx="1" transform="translate(12.728) rotate(45)" fill="#fff"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="7" rx="1" transform="translate(0 5.657) rotate(-45)" fill="#fff"/>
                                        </svg>
                                      </div>
                                    </a>
                                    <span *ngIf="!last"></span>
                                </ng-container>
                            </div>
                            <div class="textCloseToDotsInSideMenu">
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="customer_to_guest_pre"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionOne" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="fge_vision"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionTwo" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="fge_principles"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionThree" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="we_have_got_you"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionFour" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="treating_guests_like_family"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionFive" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                                <span></span>
                                <a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="what_is_in_it_for_me"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionSix" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
                            </div>
                        </div>
                    </section>
                </section>
                <button class="closeButtonInSideMenu" mat-raised-button (click)="closeSideMenu()">
                    <div class="wrapTextAndSVGInSideMenu">
                        <p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.CloseMenu" | translate}}</p>                     
                        <svg id="icon_-_close_medium" data-name="icon - close medium" xmlns="http://www.w3.org/2000/svg" width="15.557" height="15.556" viewBox="0 0 15.557 15.556">
                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" rx="1" transform="translate(0 1.414) rotate(-45)" fill="#066fef"/>
                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" rx="1" transform="translate(1.414 15.556) rotate(-135)" fill="#066fef"/>
                          </svg> 
                    </div>
                </button>                <!-- </mat-list-item> -->
        </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content>
        <div class="outerComponentColor" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
            <div class="wrapMapComponent">
                <!-- max speed for data-rellax-speed is 10 and -10 --> 
                <nav class="navigationForOnboardingComponent navigationWithPositionAbsolute">
                    <div>
                        <a routerLink='/'>{{'DetailedSectionTwo.localTopNavigation.Home' | translate}}</a>
                        <span> > </span>
                        <span>{{"DetailedSectionTwo.localTopNavigation.AboutFGE" | translate}}</span>
                    </div>
                    <div>
                        <h1 id="customer_to_guest_pre">{{"DetailedSectionTwo.localTopNavigation.SectionName" | translate}}</h1>
                    </div>
                </nav>
                <span class="Customer_to_guest" id="customer_to_guest"></span>
                <article class="content firstContainer">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="-3">
                            <p class="sectionNumberHeader">{{"DetailedSectionTwo.SectionOneContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionTwo.SectionOneContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionTwo.SectionOneContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-2', 'section-1']" (click)="sendGA4NameInfo('Customer to Guest')">{{"DetailedSectionTwo.SectionOneContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="firstImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image1_section1_part2.png" alt="{{'DetailedSectionTwo.SectionOneViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="secondImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image2_section1_part2.png" alt="{{'DetailedSectionTwo.SectionOneViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow" id="thirdImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image3_section1_part2.png" alt="{{'DetailedSectionTwo.SectionOneViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                <span class="Customer_to_guest"></span>

                <span class="Fge_vision"></span>
                <article class="content secondContainer" id="fge_vision">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="10">
                            <p class="sectionNumberHeader">{{"DetailedSectionTwo.SectionTwoContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionTwo.SectionTwoContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionTwo.SectionTwoContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-2', 'section-2']" (click)="sendGA4NameInfo('FGE Vision')">{{"DetailedSectionTwo.SectionTwoContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="fourthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image1_section2_part2.png" alt="{{'DetailedSectionTwo.SectionTwoViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="flip-right" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="fifthImageSection1Part2" src="{{'DetailedSectionTwo.SectionTwoContent.ImageSource' | translate}}" alt="{{'DetailedSectionTwo.SectionTwoViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                <span class="Fge_vision"></span>

                <span class="Fge_principles"></span>
                <article class="content thirdContainer" id="fge_principles">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="-2.5">
                            <p class="sectionNumberHeader">{{"DetailedSectionTwo.SectionThreeContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionTwo.SectionThreeContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionTwo.SectionThreeContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-2', 'section-3']" (click)="sendGA4NameInfo('FGE Principles')">{{"DetailedSectionTwo.SectionThreeContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="zoom-out-right" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="seventhImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image2_section3_part2.png" alt="{{'DetailedSectionTwo.SectionThreeViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-out-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="eighthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image1_section3_part2.png" alt="{{'DetailedSectionTwo.SectionThreeViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-out-right" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow " id="ninthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image3_section3_part2.png" alt="{{'DetailedSectionTwo.SectionThreeViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                <span class="Fge_principles"></span>

                <span class="We_have_got_you"></span>
                <article class="content fourthContainer" id="we_have_got_you">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="3">
                            <p class="sectionNumberHeader">{{"DetailedSectionTwo.SectionFourContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionTwo.SectionFourContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionTwo.SectionFourContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-2', 'section-4']" (click)="sendGA4NameInfo('We’ve Got You')">{{"DetailedSectionTwo.SectionFourContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="zoom-in-down" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="tenthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image1_section4_part2.png" alt="{{'DetailedSectionTwo.SectionFourViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-in-up" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="1000" class="customImageInTheMainFlow " id="eleventhImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image2_section4_part2.png" alt="{{'DetailedSectionTwo.SectionFourViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-in-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="500" class="customImageInTheMainFlow " id="twelfthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image3_section4_part2.png" alt="{{'DetailedSectionTwo.SectionFourViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                <span class="We_have_got_you"></span>

                <span class="Treating_guests_like_family"></span>
                <article class="content fifthContainer" id="treating_guests_like_family">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="9">
                            <p class="sectionNumberHeader">{{"DetailedSectionTwo.SectionFiveContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionTwo.SectionFiveContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionTwo.SectionFiveContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-2', 'section-5']" (click)="sendGA4NameInfo('Treating Guests Like Family')">{{"DetailedSectionTwo.SectionFiveContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="zoom-in-up" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="1500" class="customImageInTheMainFlow " id="thirteenthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image1_section5_part2.png" alt="{{'DetailedSectionTwo.SectionFiveViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-in-left" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="fourteenthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image2_section5_part2.png" alt="{{'DetailedSectionTwo.SectionFiveViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="zoom-in-right" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="500" class="customImageInTheMainFlow " id="fifteenthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image3_section5_part2.png" alt="{{'DetailedSectionTwo.SectionFiveViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                <span class="Treating_guests_like_family"></span>

                <span class="What_is_in_it_for_me"></span>
                <article class="content sixthContainer" id="what_is_in_it_for_me">
                    <div class="wrapAnimatedContent">
                        <div class="leftText rellax" data-rellax-speed="-2.5">
                            <p class="sectionNumberHeader">{{"DetailedSectionTwo.SectionSixContent.SectionNumber" | translate}}</p>
                            <h3 class="sectionNameHeader">{{"DetailedSectionTwo.SectionSixContent.SectionName" | translate}}</h3>
                            <p class="sectionNameParagraph">{{"DetailedSectionTwo.SectionSixContent.SectionDescription" | translate}}</p>
                            <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part-2', 'section-6']" (click)="sendGA4NameInfo('What’s In It for Me?')">{{"DetailedSectionTwo.SectionSixContent.VeiwSectionButton" | translate}}</a>
                        </div>
                        <div class="animatedImagesHolder">
                            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" data-aos-delay="500" data-aos-duration="1500" class="customImageInTheMainFlow " id="sixteenthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image1_section6_part2.png" alt="{{'DetailedSectionTwo.SectionSixViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="flip-down" data-aos-anchor-placement="top-bottom" data-aos-delay="1000" data-aos-duration="1000" class="customImageInTheMainFlow " id="seventeenthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image2_section6_part2.png" alt="{{'DetailedSectionTwo.SectionSixViewSectionPage.ImageAlt' | translate}}">
                            <img data-aos="flip-up" data-aos-anchor-placement="top-bottom" data-aos-delay="1500" data-aos-duration="500" class="customImageInTheMainFlow " id="eighteenthImageSection1Part2" src="../../../assets/interactive_map/main_screen_part2/image3_section6_part2.png" alt="{{'DetailedSectionTwo.SectionSixViewSectionPage.ImageAlt' | translate}}">
                        </div>
                    </div>
                </article>
                <span class="What_is_in_it_for_me"></span>

            </div>
        <!-- Footer -->
        <footer class="footerForMapComponent">
            <div class="wrapfootercomponents">
            <section>
                <p class="flexedP_inFooter {{'DetailedSectionOne.AnimatedMapFooter.ShorterLength' | translate}}">
                    <span class="blueBoldText">{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionTwo_Pre" | translate}}</span>
                    <span class="solveSpacing">
                        {{"DetailedSectionOne.AnimatedMapFooter.LeftSectionTwo" | translate}}
                    </span>
                    <br class="showOnMobileOnly" /> <span class="dontShowOnMobile">|</span>
                    <span class="lightBlueText longWordsShallNotPass">{{ sectionTitle | translate}}</span>
                  </p>
            </section>

            <div class="containerInMap">
                <ng-container *ngFor="let secondStatus of secondSectionStatus; let i = index; let last = last">
                    <a class="dots" (click)="toggleMenu()" [ngClass]="{ 'outerBorder': secondStatus || currentSectionIndex === i }">
                        <div class="mini-dot" [ngClass]="{ 'activeDot': secondStatus }"></div>
                    </a>
                    <span *ngIf="!last"></span>
                </ng-container>
            </div>
        
            <section>
                <span class="viewSections">{{"DetailedSectionOne.AnimatedMapFooter.RightSection" | translate}}</span>
                <button (click)="toggleMenu(); hideConfirmation(); closeToastOnClick();" mat-icon-button [class.active]="isActiveButton" aria-label="Example icon-button with menu icon">
                    <!-- <mat-icon>menu</mat-icon> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                        <g id="icon_-_hamburger_menu_-_default" data-name="icon - hamburger menu - default" transform="translate(6)">
                          <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="18" rx="1" transform="translate(0 2) rotate(-90)" fill="#066FEF"/>
                          <rect id="Rectangle_169" data-name="Rectangle 169" width="2" height="18" rx="1" transform="translate(0 9) rotate(-90)" fill="#066FEF"/>
                          <rect id="Rectangle_170" data-name="Rectangle 170" width="2" height="18" rx="1" transform="translate(0 16) rotate(-90)" fill="#066FEF"/>
                        </g>
                        <g id="icon_-_hamburger_menu_-_default-2" data-name="icon - hamburger menu - default">
                          <rect id="Rectangle_11-2" data-name="Rectangle 11" width="2" height="3" rx="1" transform="translate(0 2) rotate(-90)" fill="#0f0f0f"/>
                          <rect id="Rectangle_169-2" data-name="Rectangle 169" width="2" height="3" rx="1" transform="translate(0 9) rotate(-90)" fill="#0f0f0f"/>
                          <rect id="Rectangle_170-2" data-name="Rectangle 170" width="2" height="3" rx="1" transform="translate(0 16) rotate(-90)" fill="#0f0f0f"/>
                        </g>
                      </svg>
                </button>
            </section>
            </div>
        </footer>
        </div>
    </mat-drawer-content>
</mat-drawer-container>