<div class="fullHeight" [ngClass]="{'loader-active': isStyleLoading, 'extraPaddingContent': extraPaddingContent}">

<div class="contentWrapper pb-30">    
  <h1 class="page-header content-title mobile-block">{{'HomePage.AboutFGE' | translate}} 
    <div class="custom-tooltip">
      <img class="tooltip_image" (click)="showTooltip(aboutTooltipMobile)" src="../../../assets/logos/tool_tip.svg" alt="Tooltip Image"> 
       <div class="tooltip-content" id="{{'HomePage.aboutTooltipMobile' | translate}}" #aboutTooltipMobile>
        <img src="../../../assets/triangle.png" alt="" class="tooltip-arrow">
          <p>{{'HomePage.InformationalModalAbout' | translate}}</p>
          <button mat-button  class="main-button got-it-btn" (click)="hideTooltip(aboutTooltipMobile)">{{'HomePage.InformationalModalBtn' | translate}}</button>
       </div>
    </div>
  </h1>
  <div class="mobile-home-img">
    <img src="../../assets/home_image.png" alt="Homepage image">
  </div>
    <div class="homeMapBlock">
        <div class="homeMapBlock-left">
          <h1 class="page-header content-title desktop-block">{{'HomePage.AboutFGE' | translate}} 
            <div class="custom-tooltip">
              <img class="tooltip_image" (click)="showTooltip(aboutTooltip)" src="../../../assets/logos/tool_tip.svg" alt="Tooltip Image"> 
               <div class="tooltip-content" id="aboutTooltip" #aboutTooltip>
                <img src="../../../assets/triangle.png" alt="" class="tooltip-arrow">
                  <p>{{'HomePage.InformationalModalAbout' | translate}}</p>
                  <button mat-button  class="main-button got-it-btn" (click)="hideTooltip(aboutTooltip)">{{'HomePage.InformationalModalBtn' | translate}}</button>
               </div>
            </div>
          </h1>
            <!--<div class="gray-subtitle mb-10">{{'HomePage.OnboardingBoxTitle' | translate}}</div>-->
            <div class="main-text doNotApplyUppercase">
              <p class="text-left">{{'HomePage.OnboardingSectionDescription1' | translate}}</p>
              <p class="text-left">{{'HomePage.OnboardingSectionDescription2' | translate}}</p>
            </div>
            <div class="text-center mt-20 inline-element">
                <a mat-raised-button class="main-button customWidth" routerLink='/onboarding/part-1'>{{'HomePage.ExploreFGEBtn' | translate}}</a>
            </div>
            <div class="text-center mt-20 inline-element ml-30">
                <a id="dnload-wkst" class="main-link niceLineFromSomething differentColor" href="{{'SharedItems.WorkSheetUrl' | translate}}" target="_blank" (click)="downloadGA4Push()">{{'HomePage.ViewWorksheetLink' | translate}}</a>
            </div>
        </div>
        <div class="homeMapBlock-right">
          <img src="../../assets/home_image.png" alt="{{'HomePage.HomepageBannerAlt' | translate}}">
        </div>
    </div>
    <div class="mt-30">
        <div class="page-header inline-element">{{'HomePage.FGESupportGuide' | translate}}</div>       
        <img class="ml-30 sup-guide-info" src="../../../assets/logos/tool_tip.svg" alt="Info Image">
        <a [routerLink]="['/about-the-support-guide']" class="inline-element main-link differentColor niceLineFromSomething">{{'HomePage.AboutSupportGuideLink' | translate}}</a>
        <div class="categories-link"><a routerLink="/support-guide" class="inline-element allCategoryLink differentColor main-link all-cat-link niceLineFromSomething">{{'HomePage.ViewAllCategoriesLink' | translate}}</a></div>
    </div>
    <div class="mt-30">
        <mat-form-field class="home-search">
            <span matPrefix> </span>
            <input type="text" matInput placeholder="{{'HomePage.SearchInput' | translate}}" name="search" [(ngModel)]="searchTerm" #searchInput (keyup.enter)="searchItem(searchInput.value)">
            <button matSuffix mat-button class="search-button" (click)="searchItem(searchTerm)" [disabled]="!searchTerm" aria-label="search"><mat-icon>search</mat-icon></button>
        </mat-form-field>
          
    </div>
    <div class="categories-container home-page-categories">
      <div class="loaderHolder" *ngIf="apiStatus === 'pending'">
        <mat-spinner></mat-spinner>
      </div>
        <div class="categories-block">
          <ng-container *ngIf="APICards.length > 0; else noResults">
            <div class="category-row">
              <mat-card class="category-card" *ngFor="let card of APICards.slice(0, showCount)">
                <a mat-raised-button [id]="card?.id" class="linkInTheCategoryCard" [routerLink]="'/support-guide'" (click)="saveId(card?.id, card?.title)">
                  <div class="wrapIcon">
                    <img [src]="card.thumbnailUrl" alt="{{card?.title}} Icon">
                  </div>
                  <div class="wrapContent">
                    <mat-card-header>
                      <mat-card-title-group>
                        <mat-card-title [innerHTML]="card?.title"></mat-card-title>
                      </mat-card-title-group>
                    </mat-card-header>
                    <mat-card-content [innerHTML]="card?.description"></mat-card-content>
                  </div>
                </a>
              </mat-card>
            </div>
          </ng-container>
          <ng-template #noResults>
            <p>{{'HomePage.CategoriesNotFound' | translate}}</p>
          </ng-template>
        </div>
      </div>
      
    <div class="width-100 text-center mt-20">
        <button mat-button  class="main-button" *ngIf="totalCategories > showCount" (click)="showMoreCategories()">{{'HomePage.ShowMoreBtn' | translate}}</button>
    </div>    
</div>

</div>
