import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { SharedService } from '../../shared.service'; 
import { MatDrawer } from '@angular/material/sidenav';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { ProgressService } from '../../APIs/progress.service';
import AOS from 'aos';
import { FooterService } from '../../shared.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../../shared.module';
import { ToastService } from '../../../app/shared.service';
import { ToastCommunicationService } from '../../../app/shared.service'; 
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detailed-section-part2',
  templateUrl: './detailed-section-part2.component.html',
  styleUrls: ['./detailed-section-part2.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, CommonModule, RouterModule, MatListModule, MatSidenavModule, MatIconModule, MatDialogModule, SharedModule],
})
export class DetailedSectionPart2Component implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: false }) drawer!: MatDrawer;

  isActiveButton: boolean = false;
  private lastTimeout: any;

  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;
  showToast: boolean = false;
  showExtraPadding: boolean = false;
  isToastClosed: string = "";

  closeSideMenu() {
    this.drawer.close();
    this.isActiveButton = false;
    window.scrollTo({
      top: 0
    });
    window.addEventListener('scroll', this.onAutoScroll, true);
  }

  private onAutoScroll = (): void => {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
    }

    this.lastTimeout = setTimeout(() => {
      window.removeEventListener('scroll', this.onAutoScroll, true);
      window.scrollBy({
        top: -100,
        behavior: 'smooth',
      });
    }, 100);
  };

  toggleMenu() {
    this.drawer.toggle();
    this.isActiveButton = !this.isActiveButton;
  }

  hideConfirmation() {
    this.showToast = false;
    this.showExtraPadding = false;
    this.toastService.setShowToast(false);
    this.isToastClosed = "Closed";
    sessionStorage.setItem("showToastClosed", this.isToastClosed); 
  }

  closeToastOnClick() {
    this.toastCommunicationService.closeToast();
  }

  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger; 
  sectionId: string = '';
  applyClass: boolean = false; 
  sectionTitle: string = '';

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private progressService: ProgressService,
    private footerService: FooterService,
    private routeService: RouteService,
    private tagService: GA4Service,
    private translateService: TranslateService,
    private toastService: ToastService,
    private el: ElementRef,
    private toastCommunicationService: ToastCommunicationService,
  ) {}

  secondSections: any[] = [
    {
      id: 'section-1',
      title: this.translateService.instant('DetailedSectionTwo.SectionOneViewSectionPage.TopNavLinkThree'),
      applyClass: false,
    },
    {
      id: 'section-2',
      title: this.translateService.instant('DetailedSectionTwo.SectionTwoViewSectionPage.TopNavLinkThree'),
      applyClass: false,
    },
    {
      id: 'section-3',
      title: this.translateService.instant('DetailedSectionTwo.SectionThreeViewSectionPage.TopNavLinkThree'),
      applyClass: false,
    },
    {
      id: 'section-4',
      title: this.translateService.instant('DetailedSectionTwo.SectionFourViewSectionPage.TopNavLinkThree'),
      applyClass: false,
    },
    {
      id: 'section-5',
      title: this.translateService.instant('DetailedSectionTwo.SectionFiveViewSectionPage.TopNavLinkThree'),
      applyClass: false,
    },
    {
      id: 'section-6',
      title: this.translateService.instant('DetailedSectionTwo.SectionSixViewSectionPage.TopNavLinkThree'),
      applyClass: false,
    }
  ];

  getCompletedSectionsCount() {
    return this.sectionStatus.filter(status => status).length;
  }

  getCompletedSecondSectionsCount(){
    return this.secondSectionStatus.filter(status => status).length;
  }

  sectionStatus: boolean[] = new Array(11).fill(false);
  secondSectionStatus: boolean[] = new Array(6).fill(false);

  features: any[] = [];
  progressData: any[] = [];

  processProgressData(progressData: any[]) {
    for (const entry of progressData) {
      const sectionNumber = entry.objectId; // Assuming 0-10 for the first section and 11-16 for the second section
      if (sectionNumber >= 0 && sectionNumber <= 10) {
        this.sectionStatus[sectionNumber] = true; // Update the first section
      } else if (sectionNumber >= 11 && sectionNumber <= 16) {
        this.secondSectionStatus[sectionNumber - 11] = true; // Update the second section
      }
    }
  }
  
  hideFooter() {
    this.footerService.toggleFooterVisibility(false);
  }  
  showFooter() {
    this.footerService.toggleFooterVisibility(true);
  }

  ngOnInit(): void {
    this.toastService.showToast$.subscribe(showToast => {
      this.showToast = showToast;
    });

    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });

    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.focus();
    }  
    this.hideFooter();
    this.tagService.refreshPush();

    AOS.init();

    // this.setSecondSectionCompleted(this.sectionId);
    this.progressService.getFeatures().subscribe(
      (data: any) => {
        this.features = data.features;
      },
      (error) => {
        this.routeService.serverError(error);
      }
    );
  
    this.progressService.getProgress("OB").subscribe(
      (data: any) => {
        this.progressData = data.progresses;
        this.processProgressData(this.progressData);
      },
      (error) => {
        this.routeService.serverError(error);
      }
    );

    this.route.params.subscribe((params) => {
      this.sectionId = params['sectionId'];

      const currentSection = this.secondSections.find((secondSections) => secondSections.id === this.sectionId);
      if (currentSection) {
        this.sectionTitle = currentSection.title;
      }
    });

    // Subscribe to the applyClass property from the shared service
    this.sharedService.getApplyClass('section-1').subscribe((applyClass) => {
      this.sectionStatus[0] = applyClass;
    });

    this.sharedService.getApplyClass('section-2').subscribe((applyClass) => {
      this.sectionStatus[1] = applyClass;
    });

    this.sharedService.getApplyClass('section-3').subscribe((applyClass) => {
      this.sectionStatus[2] = applyClass;
    });

    this.sharedService.getApplyClass('section-4').subscribe((applyClass) => {
      this.sectionStatus[3] = applyClass;
    });

    this.sharedService.getApplyClass('section-5').subscribe((applyClass) => {
      this.sectionStatus[4] = applyClass;
    });

    this.sharedService.getApplyClass('section-6').subscribe((applyClass) => {
      this.sectionStatus[5] = applyClass;
    });

    this.sharedService.getApplyClass('section-7').subscribe((applyClass) => {
      this.sectionStatus[6] = applyClass;
    });

    this.sharedService.getApplyClass('section-8').subscribe((applyClass) => {
      this.sectionStatus[7] = applyClass;
    });

    this.sharedService.getApplyClass('section-9').subscribe((applyClass) => {
      this.sectionStatus[8] = applyClass;
    });

    this.sharedService.getApplyClass('section-10').subscribe((applyClass) => {
      this.sectionStatus[9] = applyClass;
    });

    this.sharedService.getApplyClass('section-11').subscribe((applyClass) => {
      this.sectionStatus[10] = applyClass;
    });


    this.sharedService.getApplyClass('section-1').subscribe((applyClass) => {
      this.secondSectionStatus[0] = applyClass;
    });

    this.sharedService.getApplyClass('section-2').subscribe((applyClass) => {
      this.secondSectionStatus[1] = applyClass;
    });

    this.sharedService.getApplyClass('section-3').subscribe((applyClass) => {
      this.secondSectionStatus[2] = applyClass;
    });

    this.sharedService.getApplyClass('section-4').subscribe((applyClass) => {
      this.secondSectionStatus[3] = applyClass;
    });

    this.sharedService.getApplyClass('section-5').subscribe((applyClass) => {
      this.secondSectionStatus[4] = applyClass;
    });

    this.sharedService.getApplyClass('section-6').subscribe((applyClass) => {
      this.secondSectionStatus[5] = applyClass;
    });
    
  }

  setSecondSectionCompleted(sectionId: string, sectionName: string) {
    const sectionIndex = this.secondSections.findIndex((secondSection) => secondSection.id === sectionId);
    if (sectionIndex >= 0) {
      this.secondSectionStatus[sectionIndex] = true;
    }
  
    const feature = "OB";
    const objectId = sectionIndex + 11;
  
    const payloadSecondSection = {
      feature: feature,
      objectId: objectId,
    };
  
    this.progressService.postProgressSecondSection(payloadSecondSection).subscribe(
      (response) => {
        setTimeout(() => {
          this.progressService.getProgress("OB").subscribe(
            (data: any) => {
              this.progressData = data.progresses;
              this.processProgressData(this.progressData);
            },
            (error) => {
              this.routeService.serverError(error);
            }
          )
        }, 500);
        
      },
      (error) => {
        this.routeService.serverError(error);
      }
    );
    this.tagService.completeSectionPush(sectionName);
  }
  

  closeMenu() {
    this.menuTrigger.closeMenu();
  }

  stopAnimation(className: string, sectionIdForModal: string, sectionName: any) {
    const elements = document.querySelectorAll(`.${className}`);
    elements.forEach((element) => {
      element.classList.toggle('paused');
    });
    this.tagService.sectionModalOpenPush(sectionName);
    this.openDialog(sectionIdForModal);
  }
  
  openDialog(sectionIdForModal: string) {
    const dialogRef = this.dialog.open(ModalDialogContent2, {
      data: { sectionIdForModal: sectionIdForModal },
    });
  
    dialogRef.afterOpened().subscribe(() => {      
      const keydownEvent = new KeyboardEvent('keydown', {
        key: 'Alt',
        code: 'AltLeft',
        location: 1,
        altKey: true,
      });
      const keyupEvent = new KeyboardEvent('keyup', {
        key: 'Alt',
        code: 'AltLeft',
        location: 1,
        altKey: false,
      });

      setTimeout(() => {
        window.dispatchEvent(keydownEvent);
      }, 500);

      setTimeout(() => {
        window.dispatchEvent(keyupEvent);
      }, 550);
    });
  }

  ngOnDestroy(): void {
    this.showFooter();
    this.showToastSubscription?.unsubscribe();

    setTimeout(()=>{
      window.addEventListener('scroll', this.onAutoScroll, true);
    }, 100)
  }

  downloadGA4Push(){
    this.tagService.downloadPush();
  }
}


import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'modal-dialog-part2',
  templateUrl: 'modal-dialog-part2.html',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, CommonModule, RouterModule, MatListModule, MatSidenavModule, MatIconModule, MatDialogModule, SharedModule]
})
export class ModalDialogContent2 {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { sectionIdForModal: string }, 
  private translateService: TranslateService
) {}

  activeSlide = 1;
  totalSlides = 4;

  showPreviousButton(): boolean {
    return this.activeSlide > 1;
  }

  showNextButton(): boolean {
    return this.activeSlide < this.totalSlides;
  }

  goToNextSlide() {
    if (this.activeSlide < this.totalSlides) {
      this.activeSlide++;
      this.scrollToTop();
    }
  }

  goToPreviousSlide() {
    if (this.activeSlide > 1) {
      this.activeSlide--;
      this.scrollToTop();
    }
  }

  scrollToTop() {    
    var el = document.getElementsByClassName("scrollPoint");
    for (let i = 0; i < el.length; i++) {
      el[i].scrollTop = 0;
    }    
  }
  
  isClicked: boolean = false;
  toggleClicked() {
    this.isClicked = !this.isClicked;
  }
}
