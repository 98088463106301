import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ToastService } from '../../app/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-error-pages',
  templateUrl: './error-pages.component.html',
  styleUrls: ['./error-pages.component.scss']
})
export class ErrorPagesComponent {
  constructor(private location: Location, private toastService: ToastService) {}
  
  goBack() {
    this.location.back();
  }

  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;

  ngOnInit(): void {
    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });
  }

  ngOnDestroy(): void {
    this.showToastSubscription?.unsubscribe();
  }
}
