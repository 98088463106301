<footer id="footer" *ngIf="showFooter">
  <div class="wrapFooterContent">
      <!-- <div>
        <select (change)="changeLang($event)" name="" id="">
          <option [selected]="siteLang==='en'" value="en">English</option>
          <option [selected]="siteLang==='fr'" value="fr">French </option>
        </select>
      </div> -->
  
      <div class="lowerSection">
          <a routerLink="/" >
            <img src="../../assets/logos/footer_logo.png" alt="{{'Footer.FGEGOLogoAlt' | translate}}">  
          </a>
          
          <div id="footer-link" class="footerLinks">           
          <div class="wrapFooterLinksAndText">
            <div class="upperSection" *ngIf="marketLanguages.length > 1">
              <p class="lang-title">{{'Footer.Language' | translate}}:</p>
              <mat-form-field>
                  <mat-select  [formControl]="defaultLanguage" (selectionChange)="changeLang($event)">
                    <mat-option *ngFor="let language of marketLanguages" selected="siteLang==='language.code'" [value]="language.code">{{ language.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
          </div>          
            <div class="footerLinks">                                     
              <a class="footerLink niceLineFromNothing {{'DetailedSectionOne.HideIfNeededGB' | translate}}" id="{{'DetailedSectionOne.HideIfNeededGB' | translate}}" [routerLink]="['/fge-go-website-tour']" (click)=footerGA4Push($event)>{{'Footer.WebsiteTour' | translate}}</a>
              <a class="footerLink niceLineFromNothing" [routerLink]="['/facilitator-guide']" (click)=footerGA4Push($event)>{{'Footer.FacilitatorGuide' | translate}}</a>
              <a class="footerLink niceLineFromNothing" [routerLink]="['/get-support']" (click)=footerGA4Push($event)>{{'Footer.GetSupport' | translate}}</a>
              <a class="footerLink niceLineFromNothing" href="{{'Footer.PrivacyURL' | translate}}" target="_blank" (click)=footerGA4Push($event)>{{'Footer.PrivacyPolicy' | translate}}</a>
              <a class="footerLink niceLineFromNothing {{'SharedItems.hideForCA' | translate}}" [routerLink]="['/terms-and-conditions']" (click)=footerGA4Push($event)>{{'Footer.TermsConditions' | translate}}</a>
            </div>
            <div class="theLowestSection">
              <!-- <p><span class="mr-20">© {{ getCurrentYear() }} Ford Motor Company</span><span class="no-on-mobile">|</span><a class="footerLink niceLineFromNothing optanon-toggle-display">Cookie Settings</a><span class="no-on-mobile">|</span><a class="footerLink niceLineFromNothing" id="nonFlexMobile" href="https://www.ford.com/help/contact/#accessibility" target="_blank" (click)=footerGA4Push($event)>Accessibility</a></p> -->
              <p><span class="mr-20">© {{ getCurrentYear() }} Ford Motor Company</span><span class="no-on-mobile">|</span><a class="footerLink niceLineFromNothing" id="nonFlexMobile" href="{{'Footer.AccessibilityURL' | translate}}" target="_blank" (click)=footerGA4Push($event)>{{'Footer.Accessibility' | translate}}</a></p>
            </div>
          </div>
      </div>
  </div>
</div>
</footer>