import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchService {

  constructor(private http: HttpClient, private router: Router) {}
  
 getSearchResults(searchWord: string): Observable<any>{   
      let reqBodyObj = {
        "searchTerm": searchWord,
        "contentTypeId": null,
        "categoryId": null,
        "take": 10,
        "skip": 0,
        "sortBy": ""
    }     
     var result =  this.http.post<any>(`${environment.apiUrl}/resource/search`, reqBodyObj);          
     return result;
 }; 

 getAllResults(): Observable<any>{    
      let reqBodyObj = {
        "searchTerm": "",
        "contentTypeId": null,
        "categoryId": null,
        "take": 10,
        "skip": 0,
        "sortBy": "ContentType"
    }  
     var result =  this.http.post<any>(`${environment.apiUrl}/resource/search`, reqBodyObj);          
     return result;
 }; 


 getResultsWithParam(param: any, category: any): Observable<any>{
      let reqBodyObj = {
        "searchTerm": "",
        "contentTypeId": param,
        "categoryId": category,
        "take": 10,
        "skip": 0,
        "sortBy": "ContentType"
    }  
     var result =  this.http.post<any>(`${environment.apiUrl}/resource/search`, reqBodyObj);          
     return result;
 };

 getResultsWithSort(searchTerm: any, resContentType: any, sort: any  ): Observable<any>{
  let reqBodyObj = {
    "searchTerm": searchTerm,
    "contentTypeId": resContentType,
    "categoryId": null,
    "take": 10,
    "skip": 0,
    "sortBy": sort
}  
 var result =  this.http.post<any>(`${environment.apiUrl}/resource/search`, reqBodyObj);          
 return result;
};


 getResultsFull(contentType: any, category: any, take: any, skip: any): Observable<any>{ 
    let reqBodyObj = {
      "searchTerm": "",
      "contentTypeId": contentType,
      "categoryId": category,
      "take": take,
      "skip": skip,
      "sortBy": "ContentType"
  }  
   var result =  this.http.post<any>(`${environment.apiUrl}/resource/search`, reqBodyObj);          
   return result;
};

getResultsFullwithSorting(searchTerm:any, contentType: any, category: any, take: any, skip: any, sort: any): Observable<any>{ 
  let reqBodyObj = {
    "searchTerm": searchTerm,
    "contentTypeId": contentType,
    "categoryId": category,
    "take": take,
    "skip": skip,
    "sortBy": sort
}  
 var result =  this.http.post<any>(`${environment.apiUrl}/resource/search`, reqBodyObj);          
 return result;
};


getResultsFullwithSearch(searchTerm:any, contentType: any, category: any, take: any, skip: any): Observable<any>{ 
    let reqBodyObj = {
      "searchTerm": searchTerm,
      "contentTypeId": contentType,
      "categoryId": category,
      "take": take,
      "skip": skip,
      "sortBy": "ContentType"
  }  
   var result =  this.http.post<any>(`${environment.apiUrl}/resource/search`, reqBodyObj);          
   return result;
};
}