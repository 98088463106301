import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
  })
  export class BookmarkService {  
    constructor(private http: HttpClient) {}
  
    postBookmark(id: number): Observable<any> {  
      return this.http.post<any>(`${environment.apiUrl}/resource/bookmark/${id}`, null);
    }
  }