export const environment = {
    production: false,
    apiUrl: 'https://fordisg-dev-api-app.azurewebsites.net/v1',
    useADFS: false,
    issuer: '',
    clientId: '',
    scopes: '',
    outputErrors: true,
    useExternalAuth: false,
    suffixUrl: 'home'
};
