import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Injectable } from '@angular/core';
import { LanguageService } from '../APIs/allLanguages.service';
import { FooterService } from '../shared.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { HelperService } from '../services/helper.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, FormsModule, MatSelectModule, ReactiveFormsModule, CommonModule, RouterModule, TranslateModule]
})
export class FooterComponent implements OnInit { 
  defaultLanguage = new FormControl(localStorage.getItem('siteLang'));
  languages: any[] = [];
  marketLanguages: any[] = [];
  siteLang: string = '';

  constructor(private languageService: LanguageService, private footerService: FooterService, private tagService: GA4Service, private translateService: TranslateService, private helperService: HelperService) {

  }
  showFooter = true;  

  
  
  getCurrentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit() {

    this.siteLang = localStorage.getItem('siteLang') || 'en-US';    

    this.languageService.getLanguages().subscribe((data: any) => {
      this.languages = data.languages;
      this.languages.forEach( (item, index) => {
        if(item.market.toLowerCase() === localStorage.getItem("market")?.toLowerCase()) {
         this.marketLanguages.push(this.languages[index]);            
        }        
      });
      this.defaultLanguage = new FormControl(localStorage.getItem('siteLang'));
      // const englishLanguage = this.languages.find((language) => language.name === 'English - US');
      // if (englishLanguage) {
      //   this.defaultLanguage.setValue(englishLanguage.code);
      // }
    });

    this.footerService.showFooter$.subscribe((show) => {
      this.showFooter = show;
    });    
  }

  

  changeLang(lang: any){
    const market: any = this.helperService.getMarket();
    const selLang = lang.value;
    localStorage.setItem("siteLang", selLang);
    this.translateService.use(selLang);
    this.languageService.updateUserLanguage(selLang).subscribe((response) => {      
      window.location.reload();
    });    
  }

  footerGA4Push(event: any){
    var url = event.currentTarget.href;
    var title = event.currentTarget.innerText;
    this.tagService.footerClickPush(title, url);
  }

  
}
