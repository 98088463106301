import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'formatHtml',
})
export class FormatHtmlPipe implements PipeTransform {
  private tagRules: { [key: string]: { open: string; close?: string, dynamicAttributes?: { [key: string]: string } } } = {
    italic: { open: '<i>', close: '</i>' },
    breakLine: { open: '<br />' },
    sup: { open: '<sup>', close: '</sup>' },
    a: { open: 'a', close: '</a>', dynamicAttributes: { 'target': '_blank', 'href': '' } },
    bold: {open: '<b>', close: '</b>'},
    ul: {open: '<ul>', close: '</ul>'},
    li: {open: '<li>', close: '</li>'},
    em: {open: '<em>', close: '</em>'},
    u: {open: '<u>', close: '</u>'},
    span: {open: '<span>', close: '</span>'}
  };

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    let formattedValue = value;

    for (const tag in this.tagRules) {
      const rule = this.tagRules[tag];
      formattedValue = formattedValue.replace(new RegExp(`{${tag}}`, 'g'), rule.open);

      if (tag === 'a' && rule.dynamicAttributes) {
        // Find href content between single or double quotes in the input string
        const hrefMatches = value.match(/href=(?:'([^']*)'|"([^"]*)")/);

        if (hrefMatches) {
          // Use the first non-empty match
          rule.dynamicAttributes['href'] = hrefMatches[1] || hrefMatches[2] || '';
        }

        const attributes = Object.entries(rule.dynamicAttributes).map(([key, value]) => `${key}="${value}"`).join(' ');
        formattedValue = formattedValue.replace(new RegExp(`{${tag}.*?}`), `<${rule.open} ${attributes}>`);
      }

      if (rule.close) {
        formattedValue = formattedValue.replace(new RegExp(`{/${tag}}`, 'g'), rule.close);
      }
    }

    return this.sanitizer.bypassSecurityTrustHtml(formattedValue);
  }
}
