import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { GA4Service } from 'src/app/tag-manager/push-items';


@Component({
  selector: 'app-fge-interactive-map',
  templateUrl: './fge-interactive-map.component.html',
  styleUrls: ['./fge-interactive-map.component.scss'],
})
export class FGEInteractiveMapComponent implements OnInit, OnDestroy, AfterViewInit {
  private animationIntervals: any[] = [];
  private disappearanceTimeouts: any[] = [];

  constructor(private tagService: GA4Service) {}

  ngOnInit() {
    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.focus();
    }  
    this.animateImages();
    this.shuffleImages();
    this.tagService.refreshPush();
  }

  ngOnDestroy() {
    this.animationIntervals.forEach((interval) => clearInterval(interval));
    this.disappearanceTimeouts.forEach((timeout) => clearTimeout(timeout));
  }

  ngAfterViewInit() {
    const elementToScrollTo = document.getElementById('scrollToElement');
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView();
    }
  }

  shuffleImages() {
    const wrapImages = document.querySelector('.wrapImages');
    if (wrapImages) {
      const images = Array.from(wrapImages.querySelectorAll('img'));

      for (let i = images.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [images[i], images[j]] = [images[j], images[i]];
      }

      wrapImages.innerHTML = '';

      images.forEach((img) => {
        wrapImages.appendChild(img);
      });
    }
  }

  animateImages() {
    const images = document.querySelectorAll('.wrapImages img');

    const component = this;

    function animateImage(img: any) {
      const randomInterval = Math.random() * (5000 - 3000) + 3000;
      const minScale = 0.2;
      const maxScale = 1.0;

      const randomScale = Math.random() * (maxScale - minScale) + minScale;
      img.style.transform = `scale(${0})`;
      img.style.opacity = '0';

      setTimeout(() => {
        img.style.transition = 'transform 0.5s, opacity 0.5s';
        img.style.transform = `scale(${randomScale})`;
        img.style.opacity = '1';
      }, randomInterval);

      const disappearanceTimeout = setTimeout(() => {
        img.style.transition = 'transform 0.5s, opacity 0.5s';
        img.style.transform = `scale(${0})`;
        img.style.opacity = '0';

        component.shuffleImages();
      }, randomInterval + 5500);

      component.disappearanceTimeouts.push(disappearanceTimeout);
    }

    images.forEach((img) => {
      animateImage(img);
      const animationInterval = setInterval(() => {
        animateImage(img);
      }, 8000);

      component.animationIntervals.push(animationInterval);
    });
  }

  downloadGA4Push(){
    this.tagService.downloadPush();
  }
}
