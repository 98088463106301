<!-- <h2 mat-dialog-title>Main</h2> -->
<mat-dialog-content class="customModalWidth">
    <div class="main-modal-section1" *ngIf="visible1">
        <div class="imageHolderInWelcomeModals">
            <img src="../../assets/welcome_modal/welcomeModalImage1.png" alt="{{'OnboardingModal.TopImageAlt' | translate}}">
        </div>
        <div class="main-modal-content-block">
            <h3>{{'OnboardingModal.Header' | translate}}</h3>
            <p>{{'OnboardingModal.Text1' | translate}}</p>
            <div class="main-modal-ations-row">
                <div class="wrapPaginatorInWelcomeModal">
                    <span class="active"></span>
                    <span></span>
                    <span></span>
                </div>
                <span class="nextButton" mat-raised-button (click)="goNextStep(1)">{{'OnboardingModal.Next' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="main-modal-section2" *ngIf="visible2">
        <div class="imageHolderInWelcomeModals">
            <img src="../../assets/welcome_modal/welcomeModalImage2.png" alt="{{'OnboardingModal.WelcomeImgAlt' | translate}}">
        </div>
            <div class="main-modal-content-block">
            <h3>{{'OnboardingModal.Header2' | translate}}</h3>
            <p>{{'OnboardingModal.Text2' | translate}}</p>
            <div class="main-modal-ations-row">
                <div class="wrapPaginatorInWelcomeModal">
                    <span></span>
                    <span class="active"></span>
                    <span></span>
                </div>
                <span class="nextButton" mat-raised-button (click)="goNextStep(2)">{{'OnboardingModal.Next' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="main-modal-section3" *ngIf="visible3">
        <div class="imageHolderInWelcomeModals">
            <img src="../../assets/welcome_modal/welcomeModalImage3.png" alt="{{'OnboardingModal.WelcomeImgAlt2' | translate}}">
        </div>
        <div class="main-modal-content-block">
            <h3>{{'OnboardingModal.Header3' | translate}}</h3>
            <p>{{'OnboardingModal.Text3' | translate}}</p>
            <div class="main-modal-ations-row">
                <div class="wrapPaginatorInWelcomeModal">
                    <span></span>
                    <span></span>
                    <span class="active"></span>
                </div>
                <span class="nextButton" mat-raised-button mat-dialog-close (click)="goNextStep(3)">{{'OnboardingModal.GetStarted' | translate}}</span>
            </div>
        </div>
    </div>
</mat-dialog-content>
