import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private router: Router, private http: HttpClient) {}

  getProfile(): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}/account`);
  };

  getCardById(categoryID: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/categories/${categoryID}`);
  }

  createOrUpdate(): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.apiUrl}/account`, null, { observe: 'response' });
  }
}