import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { HelperService } from '../services/helper.service';

export const MasterGuard: CanActivateFn = (route, state) => {
  const autoLoginPartialRoutesGuard = inject(AutoLoginPartialRoutesGuard);
  const helperService = inject(HelperService);

  var token = helperService.getToken();

  if (token && token !== '') {
    return true;
  }

  if (environment.useADFS) {
    return autoLoginPartialRoutesGuard.canActivate(route, state);
  }

  if (environment.useExternalAuth) {
    window.location.href = window.location.origin;
    return false;
  }

  window.location.href = '/verification';
  return false;
};