<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
  <nav class="navigationWithPositionAbsolute">
    <a class="niceLineForFooterNav" [routerLink]="['/']">{{'WebsiteTour.Home' | translate}} </a>
    <span> > </span>
    <span> {{'WebsiteTour.WebsiteTour' | translate}} </span>
  </nav>

    <div class="contentHolder">
        <h2 class="mb-30">{{'WebsiteTour.WebsiteTour' | translate}}</h2>
        <p>{{'WebsiteTour.WebsiteTourCopy' | translate}}</p>


        <div class="video-page-btn">
          <a mat-button class="main-button" href="{{'WebsiteTour.DemoVideoURL' | translate}}" target="_blank">{{'WebsiteTour.DemoVideoButton' | translate}}</a>
        </div>

        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="536" height="268" viewBox="0 0 536 268">
            <g id="Group_3592" data-name="Group 3592" transform="translate(-415 -428)">
              <rect id="Rectangle_179" data-name="Rectangle 179" width="536" height="268" rx="10" transform="translate(415 428)" fill="#066fef"/>
              <g id="Group_3600" data-name="Group 3600" transform="translate(346.625 183.141)">
                <ellipse id="Ellipse_17" data-name="Ellipse 17" cx="79" cy="77" rx="79" ry="77" transform="translate(122.375 301.859)" opacity="0.25"/>
                <rect id="Rectangle_65" data-name="Rectangle 65" width="152" height="150" transform="translate(266.375 303.859)" opacity="0.25"/>
                <path id="Polygon_1" data-name="Polygon 1" d="M87,0l87,149H0Z" transform="translate(376.375 304.859)" opacity="0.25"/>
              </g>
            </g>
          </svg>           -->
    </div>
</div>
