<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
    <nav class="navigationWithPositionAbsolute">
        <a class="niceLineForFooterNav" [routerLink]="['/']">{{'AboutFGESupport.Home' | translate}} </a>
        <span> > </span>
        <span> {{'AboutFGESupport.AboutSupport' | translate}} </span>
    </nav>
    <div class="contentHolder">
        <h2 class="mb-30">{{'AboutFGESupport.AboutFGESupport' | translate}}</h2>
        <p>{{'AboutFGESupport.AboutFGESupportP1' | translate}}</p>
        <p>{{'AboutFGESupport.AboutFGESupportP2' | translate}}</p>
        <p><b class="{{'AboutFGESupport.MarginJustForGB' | translate}}"><u>{{'AboutFGESupport.Establish' | translate}}</u></b> {{'AboutFGESupport.EstablishText' | translate}}</p>
        <p><b class="{{'AboutFGESupport.MarginJustForGB' | translate}}"><u>{{'AboutFGESupport.Expand' | translate}}</u></b> {{'AboutFGESupport.ExpandText' | translate}}</p>
        <p><b class="{{'AboutFGESupport.MarginJustForGB' | translate}}"><u>{{'AboutFGESupport.Optimize' | translate}}</u></b> {{'AboutFGESupport.OptimizeText' | translate}}</p>
        <p>{{'AboutFGESupport.AboutFGESupportP3' | translate}}</p>
        <p>{{'AboutFGESupport.AboutFGESupportP4' | translate}}</p>
        <!-- <p><a href="#" target="_blank">Action Plans in Motion</a></p>
        <p><a href="#" target="_blank">DE&I</a></p>
        <p><a href="#" target="_blank">FordPass Rewards</a></p>
        <p><a href="#" target="_blank">Mobile Service</a></p>
        <p><a href="#" target="_blank">Net Promoter Score</a></p>
        <p><a href="#" target="_blank">Online Service Reservations</a></p>
        <p><a href="#" target="_blank">Pickup & Delivery</a></p>
        <p><a href="#" target="_blank">Talent Management</a></p> -->
        <a class="linkButtomInTheAboutSupportGuide" mat-button routerLink="/support-guide">{{'AboutFGESupport.GoSupportGuide' | translate}}</a>
    </div>
</div>
