import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { bootstrapApplication,provideProtractorTestingSupport } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { APP_BASE_HREF } from '@angular/common';


document.addEventListener('DOMContentLoaded', () => {
  const baseHref = `/${environment.suffixUrl}/`;
  const providers = [{ provide: APP_BASE_HREF, useValue: baseHref }];

  platformBrowserDynamic(providers).bootstrapModule(AppModule)
    .catch(err => console.error(err));
});

// import { provideRouter } from '@angular/router';
// import routeConfig from './app/routes';



  /// <reference types="@angular/localize" />

/*
*  Protractor support is deprecated in Angular.
*  Protractor is used in this example for compatibility with Angular documentation tools.
*/

bootstrapApplication(AppComponent,
  {
    providers: [
      provideAnimations(),
      provideHttpClient(),
      importProvidersFrom(MatNativeDateModule)
    ]
  }
).catch(err => console.error(err));
