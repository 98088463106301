<nav *ngIf="showNav">
	<div class="contentWrapper" >	
		<a class="fakeButtonInHeader" id="mainLogo" tabindex="0"></a>	

		<a routerLink="/" class="noMargin main-logo">
			<img class="fge-logo" src="../assets/logos/FGE_GO_logo.png" alt="{{'Header.FGEGOLogoAlt' | translate}}">
		</a>
		<div class="link-block">
			<a class="header-link niceLineFromNothingHeader" routerLink='/support-guide'><span class="header-link-text">{{'Header.SupportGuide' | translate}}</span> 
				<img src="../assets/logos/Support_Guide.svg" alt="{{'Header.SupportGuide' | translate}}">				
			</a>

			<a tabindex="0" class="header-link niceLineFromNothingHeader" routerLink='/bookmarks'><span class="header-link-text">{{'Header.MyBookmarks' | translate}}</span>
				<img src="../assets/logos/bookmarked.svg" alt="{{'Header.MyBookmarks' | translate}}">
				<!-- <svg xmlns="http://www.w3.org/2000/svg" width="16.8" height="24" viewBox="0 0 16.8 24">
					<g id="Path_12" data-name="Path 12" fill="none">
					  <path d="M0,0H16.8V24L8.4,18,0,24Z" stroke="none"/>
					  <path d="M 1.100000381469727 1.100000381469727 L 1.100000381469727 21.86248779296875 L 8.399991035461426 16.6482105255127 L 15.70000076293945 21.86249160766602 L 15.70000076293945 1.100000381469727 L 1.100000381469727 1.100000381469727 M 0 0 L 16.80000114440918 0 L 16.80000114440918 24 L 8.399991035461426 18 L 0 24 L 0 0 Z" stroke="none" fill="#00095b"/>
					</g>
				</svg>				   -->
			</a>

			<span (click)="toggleTooltip()" (keyup.enter)="toggleTooltip()" tabindex="0" class="header-link spanInsteadOfALink">
				<img src="../assets/logos/User_icon.png" alt="{{'Header.MyProfileAlt' | translate}}" #headerModal>                                
			</span>
			  
			<div class="tooltip-content headerMenu" [ngClass]="{ 'visibleModal': isTooltipVisible }">
			<svg tabindex="0" (keyup.enter)="hideTooltip()" (click)="hideTooltip()" id="icon_-_close_medium" data-name="icon - close medium" xmlns="http://www.w3.org/2000/svg" width="15.557" height="15.556" viewBox="0 0 15.557 15.556">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" rx="1" transform="translate(0 1.414) rotate(-45)" fill="#066fef"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" rx="1" transform="translate(1.414 15.556) rotate(-135)" fill="#066fef"/>
			</svg> 
			<a (click)="hideTooltip()" tabindex="0" class="niceLineFromNothingHeader" routerLink="/profile">{{'Header.ViewProfile' | translate}}</a>
			<a (click)="signout()" tabindex="0" class="niceLineFromNothingHeader differentColorHeaderMenuLink">{{'Header.SignOut' | translate}}</a>
			</div>
		</div>		
	</div>	

	<div class="mainToastMessage" *ngIf="showToast" [ngClass]="{'extraPadding': showExtraPadding}">
		<div class="mainToastMessagePartOne">
			<svg xmlns="http://www.w3.org/2000/svg" width="21.979" height="30.417" viewBox="0 0 21.979 30.417">
				<g id="FGE_Icon_Location_RGB_Grabber" transform="translate(-143.73 -60.57)">
				  <path id="Path_102126" data-name="Path 102126" d="M157.467,86.964c3.14-3.925,8.242-10.891,8.242-15.208a10.992,10.992,0,1,0-21.979.1c0,4.219,5.2,11.284,8.34,15.11-1.374.393-2.257,1.08-2.257,1.864,0,1.177,2.159,2.159,4.808,2.159s4.808-.981,4.808-2.159C159.527,88.043,158.742,87.357,157.467,86.964ZM154.72,61.551a10.157,10.157,0,0,1,10.008,10.2c0,4.611-6.966,13.344-9.125,15.9a1.211,1.211,0,0,1-.785.393.892.892,0,0,1-.785-.393c-2.159-2.551-9.321-11.284-9.321-15.9a10.157,10.157,0,0,1,10.008-10.2Z" fill="#066FEF"/>
				  <path id="Path_102127" data-name="Path 102127" d="M266.507,181.81a5.3,5.3,0,1,0-5.3,5.2A5.194,5.194,0,0,0,266.507,181.81Zm-9.616,0a4.318,4.318,0,1,1,4.317,4.219A4.272,4.272,0,0,1,256.891,181.81Z" transform="translate(-106.489 -110.153)" fill="#066FEF"/>
				</g>
			  </svg>				   
		  <p>{{'HomePage.ToastMessage' | translate}}</p>   
		</div>
		<div class="mainToastMessagePartTwo" (click)="hideConfirmation()">
			<p class="niceLineFromSomething">{{'DetailsPage.Dismiss' | translate}}</p>
		</div>
	</div>
</nav>

<router-outlet *ngIf="showNav">

</router-outlet>

<app-footer></app-footer>