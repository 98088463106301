import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root',
})
export class Paginator {

    constructor(private router: Router) {}

    createPaginationParameters(take: number, skip: number, total: number): Observable<any> {       
        var pagesAmount;
        var activePage;

        pagesAmount = Math.floor(total/take);        
        if (total % take > 0) {
            pagesAmount = pagesAmount + 1;
        }
        if (skip > 0) {
            activePage = Math.floor(total/skip);
        } else {
            activePage = 1;
        }
        var parameters: any = [pagesAmount, activePage]
        return of(parameters); 
    }
  
}