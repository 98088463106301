import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HelperService } from './services/helper.service';
import { environment } from 'src/environments/environment';
import { FooterService } from '../app/shared.service';
import { NavService } from '../app/shared.service';
import { Subscription } from 'rxjs';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../app/APIs/profile.service';
import { ToastService } from '../app/shared.service';
import { ToastCommunicationService } from '../app/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ISG-FE';
  @ViewChild('headerModal') headerModal!: ElementRef;
  isTooltipVisible = false;
  showFooter = true;
  showNav = true;
  private footerSubscription: Subscription | undefined;
  private navSubscription: Subscription | undefined;
  siteLang: string = "";
  showToast: boolean = false;
  showExtraPadding: boolean = false;
  isToastClosed: string = "";

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private helperService: HelperService,
    private footerService: FooterService,
    private navService: NavService,
    private profileService: ProfileService,
    private tagService: GA4Service,
    private TranslateService: TranslateService,
    private toastService: ToastService,
    private toastCommunicationService: ToastCommunicationService
    ) 
   {
    this.TranslateService.setDefaultLang('en-US');
    this.TranslateService.use(localStorage.getItem('siteLang') || 'en-US')
}

ngOnInit() {
  this.toastCommunicationService.closeToast$.subscribe(() => {
    this.showToast = false;
    this.showExtraPadding = false;
    this.toastService.setShowToast(false);
    this.isToastClosed = "Closed";
    sessionStorage.setItem("showToastClosed", this.isToastClosed);
  });

  let isToastShown = sessionStorage.getItem("showToastStatus");
  this.isToastClosed = sessionStorage.getItem("showToastClosed") || "Open";

  
    if (isToastShown !== "Seen" || this.isToastClosed!=="Closed") {
      this.showToast = true;
      this.showExtraPadding = true;
      sessionStorage.setItem("showToastStatus", "Seen");
      sessionStorage.setItem("showToastClosed", "Open")
    }

    this.profileService.getProfile().subscribe((data: any) =>{
      const userLang = data.language;
      if (!localStorage.getItem('siteLang') || localStorage.getItem('siteLang') == "undefined"){
        localStorage.setItem('siteLang', userLang);
      }
      
    });

    //this.siteLang = localStorage.getItem('siteLang') || 'en-US';
    this.footerSubscription = this.footerService.showFooter$.subscribe((show) => {
      this.showFooter = show;
    });

    this.navSubscription = this.navService.showNav$.subscribe((show) => {
      this.showNav = show;
    });

    this.footerService.toggleFooterVisibility(false);
    this.navService.toggleNavVisibility(false);

    setTimeout(() => {
      this.footerService.toggleFooterVisibility(true);
      this.navService.toggleNavVisibility(true);
    }, 1000);

    if (environment.useADFS) {
      this.oidcSecurityService
        .checkAuth()
        .subscribe(({ isAuthenticated, userData, accessToken, idToken, configId }) => {
          if (isAuthenticated) {
            this.helperService.setToken(accessToken);
          }
        });
    }
  }

  hideConfirmation() {
    this.showToast = false;
    this.showExtraPadding = false;
    this.toastService.setShowToast(false);
    this.isToastClosed = "Closed";
    sessionStorage.setItem("showToastClosed", this.isToastClosed);  
  }

  toggleTooltip() {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  hideTooltip() {
    this.isTooltipVisible = false;
  }

  signout() {
    this.hideTooltip();

    this.helperService.removeToken();
    this.helperService.removeMarket();

    if (environment.useExternalAuth) {
      window.location.replace(`${window.location.origin}/logout`);
    } else if (environment.useADFS) {
      this.oidcSecurityService.logoff();
    } else {
      window.location.replace(`${window.location.origin}/${environment.suffixUrl}`);
    }

    this.tagService.signOutPush();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (this.isTooltipVisible && !this.headerModal.nativeElement.contains(event.target as Node)) {
      this.hideTooltip();
    }
  }
}
