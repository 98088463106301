<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">

<div class="contentWrapper pb-30">
  <div class="loaderHolder" *ngIf="apiStatus === 'pending'">
    <mat-spinner></mat-spinner>
  </div>
  <div class="breadcrumbs"><a routerLink='/'>{{'Bookmarks.Home' | translate}}</a> >  {{'Bookmarks.Bookmarks' | translate}}</div>
  <div class="page-header inline-element mt-20">{{'Bookmarks.Bookmarks' | translate}}</div>
  <div class="custom-tooltip">
    <img class="tooltip_image" (click)="showTooltip(bookmarkTooltip)" src="../../../assets/logos/tool_tip.svg" alt="Tooltip Image"> 
     <div class="tooltip-content" id="bookmarkTooltip" #bookmarkTooltip>
      <img src="../../../assets/triangle.png" alt="" class="tooltip-arrow">
        <p>{{'Bookmarks.PopupText' | translate}}</p>
        <button mat-button  class="main-button got-it-btn" (click)="hideTooltip(bookmarkTooltip)">{{'Bookmarks.InformationalModalBtn' | translate}}</button>
     </div>
  </div>
   
  <div class="mt-30 sg-wrapper flex-layout">       
    <div class="bookmarks-left-column">
      <mat-form-field>
        <mat-label>Select Category</mat-label>                   
        <mat-select [(value)]="selected">
          <mat-option value="All Categories" tabindex="0" id = "allCategories" (click)="changeCategoryBookmark('')">{{'Bookmarks.AllCategories' | translate}}</mat-option>                        
          <mat-option [value]="card?.title" tabindex="0" [id] = "card?.id"  *ngFor="let card of initialCards" (click)="changeCategoryBookmark(card?.id)" (keyup.enter)="changeCategoryBookmark(card?.id)">{{ card.title }}</mat-option>
        </mat-select>
    </mat-form-field>
    </div>  
    <div class="bookmarks-right-column">
        <div id="content-type-click" class="sg-top-selector-row">
          <div class="sg-selector active" tabindex="0" id="content-type-all" (click)="changeItem($event, '')">{{'Bookmarks.All' | translate}}</div>
          <div class="sg-selector" [id]="item.id" tabindex="0" *ngFor="let item of resContentTypesForHTML" (click)="changeItem($event, item.id)" [innerHTML]="item?.name"></div>
        </div>        
      </div>        
  </div> 
  <div class="categories-container bookmark-card">
    <div class="categories-block">
      <div class="category-row">
        <mat-card class="category-card search-card" *ngFor="let card of searchCards">
          <a mat-raised-button class="linkInTheCategoryCard" [routerLink]="'/support-guide/details/' + card.resourceId" (click)="resourceGA4Push(card.category, card.title, card.contentType, 'Bookmarks Page')">
            <div class="wrapIcon">
              <img [src]="card.categoryThumbnailUrl" alt="Category Image">
            </div>
            <div class="wrapContent">
              <mat-card-header>
                <mat-card-title-group>
                  <mat-card-title [innerHTML]="card.title"></mat-card-title>
                </mat-card-title-group>
              </mat-card-header>
              <mat-card-content><div [innerHTML]="card.description"></div></mat-card-content>
            </div>
            <div class="wrapDataUnderHeaderInDetailedSection">
              <p>{{ card.category }}</p>
              <p>·</p>
              <p>{{ card.contentType }}</p>
            </div>
          </a>
          <div class="ifBookmarked">
            <img (click)="onBookmarkIconClick({ resourceId: card.resourceId, isFavorite: card.isFavorite }, card.title)" src="../../../assets/logos/bookmarked-true.svg" alt="Bookmark Image">
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="custom-paginator" *ngIf="pagesList.length > 0">
    {{'SupportGuide.Page' | translate}}&nbsp;&nbsp;&nbsp;
    <div id="arrowPrev" class="prev-page hidden" *ngIf="pagesList.length > 1">
        <img src="../../../assets/arrow-left.png" tabindex="0" alt="arrow-left" (click)="changePage(prevPage, true)">
    </div>
    <div id="page{{page}}" aria-label="Page" class="custom-page" *ngFor="let page of pagesList" tabindex="0" (click)="changePage(page, null)">{{page}}</div>
    <div id="arrowNext" class="next-page" *ngIf="pagesList.length > 1">
        <img src="../../../assets/arrow-right.png" alt="arrow-right" tabindex="0" (click)="changePage(nextPage, false)">
    </div>
  </div>
  <div *ngIf="pagesList.length == 0">
    <div class="no-results-text mb-10"><h2 class="errorHeader">{{'Bookmarks.NoBookmarksTitle' | translate}}</h2></div>
    <div class="no-results-text mb-10 errorMessageHolder">{{'Bookmarks.NoBookmarksText' | translate}}</div>    
  </div>
</div>

</div>