<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">

    <div class="contentWrapper">
        <section class="wrapForm">
    
            <mat-form-field id="tokenValidatorInput" class="example-full-width">
                <mat-label>Token</mat-label>
                <input id="tokenInput" matInput placeholder="Ex. eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8..." [(ngModel)]="token" (input)="validateInput()">
            </mat-form-field>
    
            <mat-form-field id="marketValidatorInput" class="example-full-width">
                <mat-label>Market</mat-label>
                <input id="marketInput" matInput placeholder="Ex. US" [(ngModel)]="market" (input)="validateInput()">
            </mat-form-field>
    
            <button (click)="set()" [disabled]="isSetButtonDisabled" mat-raised-button color="primary">Set</button>
    
        </section>
    </div>
    </div>