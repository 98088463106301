import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  constructor(private router: Router) {} 

  private applyClassSubject = new BehaviorSubject<{ [key: string]: boolean }>({});
  private searchData = new BehaviorSubject<string>('');
  private currentID = new BehaviorSubject<any>('');
  currentSearchData = this.searchData.asObservable();
  checkID = this.currentID.asObservable();

  getApplyClass(sectionId: string): Observable<boolean> {
    return this.applyClassSubject.pipe(map((applyClassObj) => applyClassObj[sectionId] || false));
  }

  setApplyClass(sectionId: string, applyClass: boolean): void {
    const applyClassObj = { ...this.applyClassSubject.value };
    applyClassObj[sectionId] = applyClass;
    this.applyClassSubject.next(applyClassObj);
  }

  changeSearchData(data: string) {
    this.searchData.next(data);
    this.router.navigateByUrl('support-guide/search');
  }

  trackCatId(id: any) {
    this.currentID.next(id);
  }
}

export class FooterService {
  private showFooter = new BehaviorSubject<boolean>(true);
  showFooter$ = this.showFooter.asObservable();

  toggleFooterVisibility(show: boolean) {
    this.showFooter.next(show);
  }
}

export class NavService {
  private showNav = new BehaviorSubject<boolean>(true);
  showNav$ = this.showNav.asObservable();

  toggleNavVisibility(show: boolean) {
    this.showNav.next(show);
  }
}

export class ToastService {
  private showToastSubject = new BehaviorSubject<boolean>(false);
  showToast$: Observable<boolean> = this.showToastSubject.asObservable();
  private toastShown: boolean = false;

  constructor() { }

  setShowToast(showToast: boolean) {
    this.showToastSubject.next(showToast);
    this.toastShown = showToast;
  }

  isToastShown(): boolean {
    return this.toastShown;
  }
}

export class ToastCommunicationService {
  private closeToastSubject = new Subject<void>();

  closeToast$ = this.closeToastSubject.asObservable();

  closeToast() {
    this.closeToastSubject.next();
  }
  
}
