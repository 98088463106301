<!-- Modal 1 -->
<div class="modalForDetailedSection" id="rate" *ngIf="data.sectionIdForModal === 'rate'">  
    <mat-dialog-content class="mat-typography">
        <span tabindex="0" class="fakeSpan"></span>
        <div class="wrapStarsInRatingPopup">
            <h2>{{'DetailsPage.RateThisResource' | translate}}</h2>
            <p>{{'DetailsPage.HowUseful' | translate}}</p>
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
              <svg tabindex="0"
                id="icon_-_star"
                data-name="icon - star"
                width="33.421"
                height="31.822"
                viewBox="0 0 33.421 31.822"
                (mouseover)="isStarClicked ? null : highlightedStars = i + 1"
                (mouseout)="isStarClicked ? null : highlightedStars = 0"
                (click)="highlightedStars = i + 1; isStarClicked = true; onStarClick(i + 1)"
                (keyup.enter)="highlightedStars = i + 1; isStarClicked = true; onStarClick(i + 1)"
              >
                <path
                  id="Path_43"
                  data-name="Path 43"
                  d="M12.148,11.177,1.075,12.787l8.012,7.822L7.2,31.652l9.9-5.213,9.906,5.213L25.115,20.609l8.014-7.822-11.075-1.61L17.1,1.13Z"
                  transform="translate(-0.392 -0.412)"
                  [class.activeStar]="i < highlightedStars"
                  [style.fill]="i < highlightedStars ? '#066FEF' : 'transparent'"
                ></path>
                <path
                  id="Path_44"
                  data-name="Path 44"
                  d="M9.829,20.562l.133-.775L9.4,19.237,3.212,13.2l8.549-1.243.777-.112.348-.706L16.71,3.382l3.825,7.756.347.706.777.112L30.209,13.2l-6.188,6.038-.562.549.132.775,1.462,8.525L17.4,25.061l-.695-.367-.695.367L8.368,29.086ZM16.71,0,11.547,10.475,0,12.155l8.355,8.154L6.383,31.822,16.71,26.387l10.328,5.435L25.066,20.309l8.355-8.154-11.547-1.68Z"
                  transform="translate(-0.392 -0.412)"
                  [class.activeStar]="i < highlightedStars"
                  [style.fill]="i < highlightedStars ? '#066FEF' : '#6e6e6e'"
                ></path>
              </svg>
            </ng-container>
          </div>
        
          <div class="rewiewSection">
            <h2>{{'DetailsPage.HelpUsImprove' | translate}}</h2>
            <textarea
            id="feedbackField"
            name="feedbackField"
            [(ngModel)]="putComment"
            (ngModelChange)="onCommentChange()"
            [maxlength]="1000"
            [placeholder]="'DetailsPage.Placeholder' | translate"
            ></textarea>
          </div>
              
    </mat-dialog-content>
    <span class="fakeSpan"></span>
    <mat-dialog-actions id="buttonsInRating">
      <span tabindex="0" class="cancelButtonInRating" mat-dialog-close>{{'DetailsPage.Cancel' | translate}}</span>
      <button class="buttonInModal"
        mat-button
        mat-dialog-close
        [disabled]="!isDirty && (data.cardRating === null || data.cardRating === undefined)"
        (click)="submitRating();">
        {{'DetailsPage.Submit' | translate}}</button>
    </mat-dialog-actions>
</div>
<!-- End of the Modal 1 -->

<!-- Modal 2 -->
<div class="modalForDetailedSection2" id="share" *ngIf="data.sectionIdForModal === 'share'">
    <svg mat-dialog-close class="closeIconInModals" id="icon_-_close_medium" data-name="icon - close medium" xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
        <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" rx="1" transform="translate(0 1.414) rotate(-45)" fill="#066fef"/>
        <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" rx="1" transform="translate(1.414 15.556) rotate(-135)" fill="#066fef"/>
      </svg>
    <mat-dialog-content class="mat-typography">
        
        <div class="wrapStarsInRatingPopup">
            <h2>{{'DetailsPage.ShareURL' | translate}}</h2>
            <p>{{'DetailsPage.YourLinkIsReady' | translate}}</p>
        </div>
        
        <div class="rewiewSection">
            <input
            id="feedbackField2"
            name="feedbackField"
            [value]="data.cardResourceURL"
            (keyup)="shareGA4Push($event)"
            readonly
            #userinput
          >

            <button class="buttonInModal" 
            mat-button
            mat-dialog-close
            (click)="copyInputMessage(userinput)"
            >
            {{'DetailsPage.CopyLink' | translate}}</button>
        </div>

        <!-- <input type="text" value="Enter text to be copied" #userinput>
        <button (click)="copyInputMessage(userinput)" value="click to copy">Copy from Textbox</button>         -->
              
    </mat-dialog-content>
</div>
<!-- End of the Modal 2 -->