import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelperService } from '../services/helper.service';
import { RouteService } from '../services/route.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private routeService: RouteService, private helperService: HelperService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(environment.apiUrl)) {
      return next.handle(request);
    }
    
    const token = this.helperService.getToken() || '';
    const market = this.helperService.getMarket() || '';

    if (token === '' || !token) {
      return next.handle(request);
    }

    const authReq = request.clone({
      headers: request.headers
        .set('Authorization', 'Bearer ' + token)
        .set('X-Market', market)
    });

    // Handling errors within the observable chain
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.routeService.unauthorized();
        }
        else if (error.status >= 500 && error.status <= 511) {
          this.routeService.serverError(error);
        }
        else if (error.status == 404) {
          this.routeService.error();
        }

        if (environment.outputErrors) {
          return throwError(() => error);
        }
        
        return EMPTY;
      })
    );
  }
}
