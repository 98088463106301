import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  constructor(private _router: Router, private oidcSecurityService: OidcSecurityService) {}

  home() {
    this._router.navigate(['']);
  }

  error() {
    this._router.navigate(['/error'])
  }

  serverError(error?: any) {
    this._router.navigate(['/error/server'])
  }
  
  unauthorized() {  
    this._router.navigate(['/error/unauthorized'])
  }
}