import { Component } from '@angular/core';
import { ProfileService } from '../../APIs/profile.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { ToastService } from '../../../app/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  firstName:any;
  lastName:any;
  profileLang:any;
  dealerName:any;
  storeId:any;
  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;

  constructor(private ProfileService: ProfileService, private tagService: GA4Service, private toastService: ToastService) {}

  ngOnInit() {
    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });

    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.focus();
    }  
    this.tagService.refreshPush();
    this.ProfileService.getProfile().subscribe((data: any) => {
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.profileLang = data.language;
      this.dealerName = data.dealerName; 
      this.storeId = data.storeId;   
    });
  }

  ngOnDestroy(): void {
    this.showToastSubscription?.unsubscribe();
  }

}


