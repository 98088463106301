import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class LanguageService {  
  constructor(private http: HttpClient) {}

  getLanguages(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/metadata/languages`);
  }

  updateUserLanguage(language: string): Observable<any> {
    const params = new HttpParams().set('language', language);

    return this.http.put(`${environment.apiUrl}/account/language`, null, { params });
  }
}
