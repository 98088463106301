<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
    <div class="contentHolder">
        <div class="topContent">
            <svg id="ui_-_error_19_x_19" data-name="ui - error 19 x 19" xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19">
                <g id="Polygon_26" data-name="Polygon 26" fill="none" stroke-linejoin="round">
                  <path d="M9.269,2.99a2,2,0,0,1,3.462,0L20.262,16a2,2,0,0,1-1.731,3H3.469a2,2,0,0,1-1.731-3Z" stroke="none"/>
                  <path d="M 10.99999904632568 2.991722106933594 C 10.8266487121582 2.991722106933594 10.39529895782471 3.040342330932617 10.13457870483398 3.490692138671875 L 2.603469848632812 16.49897193908691 C 2.342199325561523 16.95024108886719 2.515798568725586 17.34899139404297 2.602569580078125 17.49948120117188 C 2.689329147338867 17.64997291564941 2.947439193725586 18.00000190734863 3.468898773193359 18.00000190734863 L 18.53110885620117 18.00000190734863 C 19.05255889892578 18.00000190734863 19.3106689453125 17.64997291564941 19.39742851257324 17.49948120117188 C 19.48419952392578 17.34899139404297 19.65779876708984 16.95024108886719 19.39652824401855 16.49897193908691 L 11.86541938781738 3.490692138671875 C 11.60469913482666 3.040342330932617 11.17334938049316 2.991722106933594 10.99999904632568 2.991722106933594 M 10.99999904632568 1.991724014282227 C 11.67284107208252 1.991724014282227 12.34568405151367 2.324367523193359 12.73084926605225 2.989652633666992 L 20.26195907592773 15.99793243408203 C 21.03388977050781 17.33126258850098 20.07176971435547 19.00000190734863 18.53110885620117 19.00000190734863 L 3.468898773193359 19.00000190734863 C 1.928228378295898 19.00000190734863 0.9661083221435547 17.33126258850098 1.738039016723633 15.99793243408203 L 9.269148826599121 2.989652633666992 C 9.654314041137695 2.324367523193359 10.32715702056885 1.991724014282227 10.99999904632568 1.991724014282227 Z" stroke="none" fill="#d9005c"/>
                </g>
                <rect id="Rectangle_1289" data-name="Rectangle 1289" width="2" height="5" transform="translate(10 11)" fill="#d9005c"/>
                <rect id="Rectangle_1290" data-name="Rectangle 1290" width="2" height="2" transform="translate(10 7)" fill="#d9005c"/>
              </svg>          
            <h2>{{'ErrorPages.AccessDenied' | translate}}</h2>
        </div>

        <div class="mainContent">
            <p id="centered">{{'ErrorPages.PleaseEnsure' | translate}}</p>
        </div>

        <div class="errorButtons">
            <a mat-button (click)="goBack()">{{'ErrorPages.GoBack1' | translate}}</a>
            <a mat-button (click)="clearStorage()" routerLink="/">{{'ErrorPages.HomePage' | translate}}</a>
        </div>
    </div>
</div>
