import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperService } from '../services/helper.service';
import { ToastService } from '../../app/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-verification',
  templateUrl: 'verification.component.html',
  styleUrls: ['./verification.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, MatInputModule, FormsModule, CommonModule]
})
export class VerificationComponent implements OnInit{
  token: string | null = "";
  market: string | null = "";
  isSetButtonDisabled: boolean = true;
  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;

  constructor(public dialog: MatDialog, private router: Router,
    private helperService: HelperService, private toastService: ToastService) {}

  ngOnInit(): void {
    this.token = this.helperService.getToken();
    this.market = this.helperService.getMarket();

    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });
  }

  validateInput() {  
    this.isSetButtonDisabled = !this.token || this.token === '' || !this.market || this.market === '';
  }
  
  set() {
    this.helperService.setToken(this.token || "");
    this.helperService.setMarket(this.market || "");
    //window.location.href = '/'
    this.router.navigateByUrl('/');
  }

  ngOnDestroy(): void {
    this.showToastSubscription?.unsubscribe();
  }
}