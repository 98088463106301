import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { HostListener } from '@angular/core';
import Rellax from 'rellax';
import { SharedService } from '../../shared.service';
import { FooterService } from '../../shared.service';
import { ProgressService } from '../../APIs/progress.service';
import { MatDrawer } from '@angular/material/sidenav';
import AOS from 'aos';
import { RouteService } from 'src/app/services/route.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../app/shared.service';
import { ToastCommunicationService } from '../../../app/shared.service'; 
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-animated-map-part1',
  templateUrl: './animated-map-part1.component.html',
  styleUrls: ['./animated-map-part1.component.scss'],
})

export class AnimatedMapPart1Component implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('drawer', { static: false }) drawer!: MatDrawer;

  contentHeight: number | undefined;
  isActiveButton: boolean = false;
  currentSectionIndex: number | null = null;

  private showToastSubscription: Subscription | undefined;
  private lastTimeout: any;

  extraPaddingContent: boolean = false;
  showToast: boolean = false;
  showExtraPadding: boolean = false;
  isToastClosed: string = "";

  hideConfirmation() {
    this.showToast = false;
    this.showExtraPadding = false;
    this.toastService.setShowToast(false);
    this.isToastClosed = "Closed";
    sessionStorage.setItem("showToastClosed", this.isToastClosed); 
  }

  closeToastOnClick() {
    this.toastCommunicationService.closeToast();
  }

  closeSideMenu() {
    this.drawer.close();
    this.isActiveButton = false;
    window.scrollTo({
      top: 0
    });
    window.addEventListener('scroll', this.onAutoScroll, true);
  }

  private onAutoScroll = (): void => {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
    }

    this.lastTimeout = setTimeout(() => {
      window.removeEventListener('scroll', this.onAutoScroll, true);
      window.scrollBy({
        top: -100,
        behavior: 'smooth',
      });
    }, 100);
  };

  toggleMenu() {
    this.drawer.toggle();
    this.isActiveButton = !this.isActiveButton;
  }

  getCompletedSectionsCount() {
    return this.sectionStatus.filter(status => status).length;
  }

  getCompletedSecondSectionsCount(){
    return this.secondSectionStatus.filter(status => status).length;
  }

  sectionStatus: boolean[] = new Array(11).fill(false);
  secondSectionStatus: boolean[] = new Array(6).fill(false);

  applyClass: boolean = false;
  private applyClassSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private sharedService: SharedService,
    private progressService: ProgressService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private footerService: FooterService,
    private routeService: RouteService,
    private tagService: GA4Service,
    private translateService: TranslateService,
    private toastService: ToastService,
    private toastCommunicationService: ToastCommunicationService,
  ) {}

  features: any[] = [];
  progressData: any[] = [];

  processProgressData(progressData: any[]) {
    for (const entry of progressData) {
      const sectionNumber = entry.objectId; // Assuming 0-10 for the first section and 11-16 for the second section
      if (sectionNumber >= 0 && sectionNumber <= 10) {
        this.sectionStatus[sectionNumber] = true; // Update the first section
      } else if (sectionNumber >= 11 && sectionNumber <= 16) {
        this.secondSectionStatus[sectionNumber - 11] = true; // Update the second section
      }
    }
  }

    hideFooter() {
      this.footerService.toggleFooterVisibility(false);
    }  
    showFooter() {
      this.footerService.toggleFooterVisibility(true);
    }

  ngOnInit() {
    this.toastService.showToast$.subscribe(showToast => {
      this.showToast = showToast;
    });

    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });

    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.focus();
    }  
    this.onScroll();

    this.hideFooter();

    setTimeout(() => {
      this.progressService.getFeatures().subscribe(
        (data: any) => {
          this.features = data.features;
        },
        (error) => {
          this.routeService.serverError(error);
        }
      );
    
      this.progressService.getProgress("OB").subscribe(
        (data: any) => {
          this.progressData = data.progresses;
          this.processProgressData(this.progressData);
        },
        (error) => {
          this.routeService.serverError(error);
        }
      );    
    }, 500);


    const rellax = new Rellax('.rellax');
    rellax.destroy();
    setTimeout(() => {
      AOS.init();
      const rellax = new Rellax('.rellax', {
        center: true
      });
    }, 100);

    this.sharedService.getApplyClass('section-1').subscribe((applyClass) => {
      this.sectionStatus[0] = applyClass;
    });

    this.sharedService.getApplyClass('section-2').subscribe((applyClass) => {
      this.sectionStatus[1] = applyClass;
    });

    this.sharedService.getApplyClass('section-3').subscribe((applyClass) => {
      this.sectionStatus[2] = applyClass;
    });

    this.sharedService.getApplyClass('section-4').subscribe((applyClass) => {
      this.sectionStatus[3] = applyClass;
    });

    this.sharedService.getApplyClass('section-5').subscribe((applyClass) => {
      this.sectionStatus[4] = applyClass;
    });

    this.sharedService.getApplyClass('section-6').subscribe((applyClass) => {
      this.sectionStatus[5] = applyClass;
    });

    this.sharedService.getApplyClass('section-7').subscribe((applyClass) => {
      this.sectionStatus[6] = applyClass;
    });

    this.sharedService.getApplyClass('section-8').subscribe((applyClass) => {
      this.sectionStatus[7] = applyClass;
    });

    this.sharedService.getApplyClass('section-9').subscribe((applyClass) => {
      this.sectionStatus[8] = applyClass;
    });

    this.sharedService.getApplyClass('section-10').subscribe((applyClass) => {
      this.sectionStatus[9] = applyClass;
    });

    this.sharedService.getApplyClass('section-11').subscribe((applyClass) => {
      this.sectionStatus[10] = applyClass;
    });




    this.sharedService.getApplyClass('section-1').subscribe((applyClass) => {
      this.secondSectionStatus[0] = applyClass;
    });

    this.sharedService.getApplyClass('section-2').subscribe((applyClass) => {
      this.secondSectionStatus[1] = applyClass;
    });

    this.sharedService.getApplyClass('section-3').subscribe((applyClass) => {
      this.secondSectionStatus[2] = applyClass;
    });

    this.sharedService.getApplyClass('section-4').subscribe((applyClass) => {
      this.secondSectionStatus[3] = applyClass;
    });

    this.sharedService.getApplyClass('section-5').subscribe((applyClass) => {
      this.secondSectionStatus[4] = applyClass;
    });

    this.sharedService.getApplyClass('section-6').subscribe((applyClass) => {
      this.secondSectionStatus[5] = applyClass;
    });
  }

  isContentShown: boolean = false;

  toggleContent() {
    this.isContentShown = !this.isContentShown;
  }

  ngOnDestroy() {
    this.showToastSubscription?.unsubscribe();

    const rellax = new Rellax('.rellax');
    if (rellax) {
      rellax.destroy();
    }
    this.applyClassSubscription.unsubscribe();

    this.showFooter();

    setTimeout(()=>{
      window.addEventListener('scroll', this.onAutoScroll, true);
    }, 100)

    // window.removeEventListener('scroll', this.onAutoScroll, true);
    // if (this.lastTimeout) {
    //   clearTimeout(this.lastTimeout);
    // }  
  }

  ngAfterViewInit() {
    this.onScroll();
  }

  sectionTitle: string = '';


sectionTitleMapping: Record<string, { title: string, index: number }> = {
  'Diversity': { title: this.translateService.instant('DetailedSectionOne.SectionOneViewSectionPage.TopNavLinkThree'), index: 0 },
  'Gender': { title: this.translateService.instant('DetailedSectionOne.SectionTwoViewSectionPage.TopNavLinkThree'), index: 1 },
  'Age': { title: this.translateService.instant('DetailedSectionOne.SectionThreeViewSectionPage.TopNavLinkThree'), index: 2 },
  'Generation': { title: this.translateService.instant('DetailedSectionOne.SectionFourViewSectionPage.TopNavLinkThree'), index: 3 },
  'EVs': { title: this.translateService.instant('DetailedSectionOne.SectionFiveViewSectionPage.TopNavLinkThree'), index: 4 },
  'Online_sales': { title: this.translateService.instant('DetailedSectionOne.SectionSixViewSectionPage.TopNavLinkThree'), index: 5 },
  'Guest_personas': { title: this.translateService.instant('DetailedSectionOne.SectionSevenViewSectionPage.TopNavLinkThree'), index: 6 },
  'Happy_brain_anxious_brain': { title: this.translateService.instant('DetailedSectionOne.SectionEightViewSectionPage.TopNavLinkThree'), index: 7 },
  'Guest_representation': { title: this.translateService.instant('DetailedSectionOne.SectionNineViewSectionPage.TopNavLinkThree'), index: 8 },
  'Relationship_building_with_guests': { title: this.translateService.instant('DetailedSectionOne.SectionTenViewSectionPage.TopNavLinkThree'), index: 9 },
  'Last_best_experience': { title: this.translateService.instant('DetailedSectionOne.SectionElevenViewSectionPage.TopNavLinkThree'), index: 10 },
};

@HostListener('window:scroll', ['$event'])
onScroll() {
  const currentPosition = window.scrollY;
  const viewportHeight = window.innerHeight;
  let offsetPercentage: number;
  offsetPercentage = 10;

  const offset = (viewportHeight * offsetPercentage) / 100;

  for (const sectionClass in this.sectionTitleMapping) {
    const sectionElement = this.getElement(sectionClass);

    if (sectionElement) {
      const sectionTop = sectionElement.offsetTop;
      const sectionBottom = sectionTop + sectionElement.clientHeight;

      if (currentPosition >= sectionTop - offset && currentPosition < sectionBottom) {
        const sectionData = this.sectionTitleMapping[sectionClass];

        this.sectionTitle = sectionData.title;

        const sectionIndex = sectionData.index;

        this.currentSectionIndex = sectionIndex;

        return;
      }
    }
  }
}


getElement(className: string): HTMLElement | null {
  const elements = document.getElementsByClassName(className);
  if (elements.length > 0) {
    return elements[0] as HTMLElement;
  }
  return null;
}

sendGA4NameInfo(itemName: any){
  this.tagService.viewSectionPush(itemName);
}

downloadGA4Push(){
  this.tagService.downloadPush();
}

}