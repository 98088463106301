import { Component } from '@angular/core';
import { CardService } from '../../APIs/card.service';
import { SearchService } from '../../APIs/search.service';
import { Paginator } from '../../paginator';
import { RouteService } from 'src/app/services/route.service';
import { BookmarkService } from '../../APIs/bookmarked.service';
import { ChangeDetectorRef } from '@angular/core';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { ToastService } from '../../../app/shared.service';
import { Subscription } from 'rxjs';

interface ResourceForBookmarkIcon {
  resourceId: number;
  isFavorite: boolean;
}

@Component({
  selector: 'app-bookmarks-page',
  templateUrl: './bookmarks-page.component.html',
  styleUrls: ['./bookmarks-page.component.scss']
})
export class BookmarksPageComponent {
  searchCards: any[] = [];
  resContentTypes: any[] = [];
  resContentTypesForHTML: any[] = [];
  allBlock: any[] = [];
  initialCards: any[] = [];
  resContentType: any = null;
  take: number = 0;
  skip: number = 0;
  total: number = 0;
  pagesAmount: number = 0;
  pagesStep: number = 0;
  pagesList: any[] = [];
  activePage: number = 1;
  pageNum: number = 0;
  prevPage: number = 0;
  nextPage: number = 0;
  activeResContentType: any = ""; 
  activeCategory: any = "";
  shareLink = '';
  selected = "All Categories";
  apiStatus: string = 'pending';

  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;

  constructor( private searchService: SearchService, private cardService: CardService, private paginator: Paginator, private routeService: RouteService, private BookmarkService: BookmarkService, private cdr: ChangeDetectorRef, private tagService: GA4Service, private toastService: ToastService) {}

  ngOnInit(): void {
    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });

    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.focus();
    }  
    this.tagService.refreshPush();
    this.resContentType = "";
    this.activeCategory = "";
    let CatNumber = sessionStorage.getItem("CategoriesNumber");
    this.cardService.getCardsWithParam(CatNumber).subscribe((data) => {
      const typedData = data as { items: any[] };   
      this.initialCards = typedData.items;
      this.apiStatus = 'success';      
    });

    this.cardService.getResourceContentTypes().subscribe((data) => {
      const resData = data as { contentTypes: any[] };            
      this.resContentTypes = resData.contentTypes;
      this.resContentTypesForHTML =  this.resContentTypes.filter(x => x.languageCode == localStorage.getItem("siteLang"));
    });

    this.cardService.getBookmarked().subscribe((data) => {
      const searchData = data as { items: any[] };   
      this.searchCards = searchData.items;
      this.paginatorLoader(searchData);
    });
  }

  ngOnDestroy(): void {
    this.showToastSubscription?.unsubscribe();
  }

  changeItem(event: any, resContentType: any){   
    var activeEl;
    this.tagService.refinePush(event.srcElement.innerText);
    this.resContentType = resContentType;
    activeEl = document.getElementsByClassName("sg-selector active")[0];
    if (event.target.classList.contains("active")){
      return;
    } else {
      event.target.classList.add('active');
      activeEl.classList.remove('active');
    }    

    this.cardService.getBookmarkedWithCategory(resContentType, this.activeCategory).subscribe((data) => {
      const searchData = data as { items: any[] };   
      this.searchCards = searchData.items;
      this.paginatorLoader(searchData);
    });
  }

  changeCategoryBookmark(category: any){
    this.activeCategory = category;
    this.cardService.getBookmarkedWithCategory(this.resContentType, category).subscribe((data) => {
      const searchData = data as { items: any[] };   
      this.searchCards = searchData.items;
      this.paginatorLoader(searchData);
      var activeNum = document.querySelectorAll(".custom-page.active");
       activeNum[0].classList.remove("active"); 
      
      document.getElementById("arrowPrev")?.classList.add("hidden");
      document.getElementById("arrowNext")?.classList.remove("hidden"); 
    });
  }

  updatePagesList() {
    const maxPagesToShow = 5;
    const middlePage = Math.ceil(maxPagesToShow / 2);
  
    if (this.pagesAmount <= maxPagesToShow) {
      this.pagesList = Array.from({ length: this.pagesAmount }, (_, i) => i + 1);
    } else {
      if (this.activePage <= middlePage) {
        // Display the first maxPagesToShow pages and add the last page with ...
        this.pagesList = Array.from({ length: maxPagesToShow }, (_, i) => i + 1);
        this.pagesList.push('...');
        this.pagesList.push(this.pagesAmount);
      } else if (this.activePage > this.pagesAmount - middlePage) {
        // Display the last maxPagesToShow pages, including the first page with ...
        this.pagesList = [1, '...'];
        this.pagesList = this.pagesList.concat(
          Array.from({ length: maxPagesToShow - 2 }, (_, i) => this.pagesAmount - maxPagesToShow + i + 3)
        );
      } 
      else {
        // Display the current page and the pages around it with the first and last page with ...
        this.pagesList = [1, '...'];
        this.pagesList = this.pagesList.concat(
          Array.from({ length: maxPagesToShow - 2 }, (_, i) => this.activePage - middlePage + i + 2)
        );
        this.pagesList.push('...');
        this.pagesList.push(this.pagesAmount);
      }
    }
  } 

  paginatorLoader(data: any){
    this.pagesList = [];
    this.pagesStep = 0;
    this.take = parseInt(data.take);
    this.skip = parseInt(data.skip);
    this.total = parseInt(data.total);
    this.activePage = 1;
    this.paginator.createPaginationParameters(this.take, this.skip, this.total).subscribe((parameters) => {
      this.pagesAmount = parameters[0];
      for(let i = 0; i < this.pagesAmount; i++){   
          this.pagesStep = this.pagesStep + 1;
          this.pagesList.push(this.pagesStep);              
        }        
    });
    document.getElementById("arrowPrev")?.classList.add("hidden");
    setTimeout("document.getElementById('page1')?.classList.add('active')", 1000);  
    
    this.updatePagesList();
  }

  changePage(pageNum: number, arrowButton: any){
    var activeNum = document.querySelectorAll(".custom-page.active");
    if (activeNum && activeNum.length > 0) {
      activeNum[0].classList.remove("active");
    }
          
    if (pageNum > 0){
      this.activePage = pageNum;      
    } else if (pageNum == 0 && arrowButton == true){
      this.activePage = this.activePage - 1;
    } else if (pageNum == 0 && arrowButton == false){
      this.activePage = this.activePage + 1;
    }
    document.getElementById("page" + this.activePage)?.classList.add("active");
    if (this.activePage == 1){
      document.getElementById("arrowPrev")?.classList.add("hidden");
      document.getElementById("arrowNext")?.classList.remove("hidden");
    } else if (this.activePage == this.pagesAmount){
      document.getElementById("arrowPrev")?.classList.remove("hidden");
      document.getElementById("arrowNext")?.classList.add("hidden");
    } else {
      document.getElementById("arrowPrev")?.classList.remove("hidden");
      document.getElementById("arrowNext")?.classList.remove("hidden");
    }
    
    this.skip = 0;
    // if arrowButton is true - we clicked Previous Page, is false - we clicked Next Page, if null we clicked on page number
    
    this.take = 10;
    this.skip = this.activePage - 1;    
    if (this.activeResContentType == undefined){
      this.activeResContentType = null;
    }
    
    this.cardService.getBookmarkedAllParam(this.activeResContentType, this.activeCategory,  this.skip, this.take).subscribe((data) => {
      const searchData = data as { items: any[] };   
      this.searchCards = searchData.items;
    });

    this.updatePagesList();
  }

  showTooltip(id:any){
    document.getElementById(id.id)?.setAttribute("style", "display: block;");;
  }

  hideTooltip(id:any){
    document.getElementById(id.id)?.setAttribute("style", "display: none;");;
  }

  onBookmarkIconClick(resource: ResourceForBookmarkIcon, title: any) {
    const resourceId = resource.resourceId;
  
    this.BookmarkService.postBookmark(resourceId).subscribe(
      (response) => {
        this.tagService.resourceEngagement('remove bookmark', title);
        resource.isFavorite = !resource.isFavorite;        
  
        if (!resource.isFavorite) {
          this.searchCards = this.searchCards.filter((card) => card.resourceId !== resourceId);
        }
  
        this.cdr.detectChanges();
      },
      (error) => {
      }
    );
  }

  resourceGA4Push(category: any, title: any, contentType: any, selectionType: any){
    this.tagService.resourceSelectedPush(category, title, contentType, selectionType);
  }
}