<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
    <nav class="navigationWithPositionAbsolute">
        <a class="niceLineForFooterNav" [routerLink]="['/']">{{'FacilitatorGuide.Home' | translate}} </a>
        <span> > </span>
        <span> {{'FacilitatorGuide.FacilitatorGuide' | translate}} </span>
    </nav>
    <div class="contentHolder">
        <h2 class="mb-30">{{'FacilitatorGuide.FacilitatorGuide' | translate}}</h2>
        <p>{{'FacilitatorGuide.FacilitatorGuideText' | translate}}</p>
        <a mat-button href="{{'SharedItems.FacilitatorsURL' | translate}}" target="_blank">{{'FacilitatorGuide.FacilitatorGuide' | translate}}</a>
    </div>
</div>
