import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { HelperService } from 'src/app/services/helper.service';
import { ToastService } from '../../../app/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unauth',
  templateUrl: './unauth.component.html',
  styleUrls: ['./unauth.component.scss']
})
export class UnauthComponent {
  constructor(private location: Location, private helperService: HelperService, private toastService: ToastService) {}
  
  goBack() {
    this.helperService.removeToken();
    this.helperService.removeMarket();

    this.location.back();
  }

  clearStorage() {
    this.helperService.removeToken();
    this.helperService.removeMarket();
  }

  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;

  ngOnInit(): void {
    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });
  }

  ngOnDestroy(): void {
    this.showToastSubscription?.unsubscribe();
  }
}