import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormatHtmlPipe } from './services/formatHtmlForTranslation';

@NgModule({
  declarations: [FormatHtmlPipe],
  imports: [TranslateModule.forChild()],
  exports: [FormatHtmlPipe, TranslateModule],
})
export class SharedModule {}
