import { Component } from '@angular/core';
import { ToastService } from '../../app/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-facilitator-guide',
  templateUrl: './facilitator-guide.component.html',
  styleUrls: ['./facilitator-guide.component.scss']
})
export class FacilitatorGuideComponent {
  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });
  }

  ngOnDestroy(): void {
    this.showToastSubscription?.unsubscribe();
  }
}
