<mat-drawer-container autosize="">
	<mat-drawer #drawer opened="false" mode="push" position="end">
			<mat-nav-list>
				<!-- <mat-list-item> -->
					<section class="wrapSlideMenuHeader">
						<p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.JumpToSection" | translate}}</p>
						<a id="dnload-wkst" class="makeCertainSize" mat-raised-button href="{{'SharedItems.WorkSheetUrl' | translate}}" target="_blank" (click)="downloadGA4Push()">{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.ViewWorksheet" | translate}}</a>
					</section>
					<section class="wrapSlideMenuBody">
						<div class="headerDivInSlideMenuBody">
							<h4><b>{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionFirst" | translate}}</b></h4>
							<p>{{ getCompletedSectionsCount() }} {{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.Of_Eleven_Completed" | translate}}</p>
						</div>
	
						<section class="mainNavSectionInSlideMenuBody">
						<!-- First Part -->
							<div class="wrapDotsAndWords">
								<div class="navigationDotsInSlideMenu">
									<ng-container *ngFor="let status of sectionStatus; let i = index; let last = last">
										<a class="dots" [ngClass]="{ 'outerBorder': status }">
											<div class="mini-dot" [ngClass]="{ 'activeDot': status }">
												<svg id="icon_-_checkmark_small" data-name="icon - checkmark small" xmlns="http://www.w3.org/2000/svg" width="14.142" height="10.607" viewBox="0 0 14.142 10.607">
													<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="13" rx="1" transform="translate(12.728) rotate(45)" fill="#fff"/>
													<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="7" rx="1" transform="translate(0 5.657) rotate(-45)" fill="#fff"/>
												</svg>  
											</div>
										</a>
										<span *ngIf="!last"></span>
									</ng-container>
								</div>
								<div class="textCloseToDotsInSideMenu">
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="diversity"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionOne" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="gender"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTwo" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="age"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionThree" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="generation"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFour" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="evs"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFive" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="online_sales"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSix" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest_personas"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSeven" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEight" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest_representation"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionNine" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTen" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="last_best_experience"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEleven" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
								</div>
							</div>
						</section>
					</section>
	
	
					<section class="wrapSlideMenuBody">
						<div class="headerDivInSlideMenuBody">
							<h4><b>{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionSecond" | translate}}</b></h4>
							<p>{{ getCompletedSecondSectionsCount() }} {{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.Of_Six_Completed" | translate}}</p>
						</div>
	
						<section class="mainNavSectionInSlideMenuBody">
							<div class="wrapDotsAndWords">
								<div class="navigationDotsInSlideMenu">
									<ng-container *ngFor="let secondStatus of secondSectionStatus; let i = index; let last = last">
										<a class="dots" [ngClass]="{ 'outerBorder': secondStatus }">
										  <div class="mini-dot" [ngClass]="{ 'activeDot': secondStatus }">
											<svg id="icon_-_checkmark_small" data-name="icon - checkmark small" xmlns="http://www.w3.org/2000/svg" width="14.142" height="10.607" viewBox="0 0 14.142 10.607">
                                                <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="13" rx="1" transform="translate(12.728) rotate(45)" fill="#fff"/>
                                                <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="7" rx="1" transform="translate(0 5.657) rotate(-45)" fill="#fff"/>
                                            </svg>  
										  </div>
										</a>
										<span *ngIf="!last"></span>
									</ng-container>
								</div>
								<div class="textCloseToDotsInSideMenu">
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="customer_to_guest"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionOne" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="fge_vision"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionTwo" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="fge_principles"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionThree" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="we_have_got_you"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionFour" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="treating_guests_like_family"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionFive" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="what_is_in_it_for_me"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionSix" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
								</div>
							</div>
						</section>
					</section>
					<button class="closeButtonInSideMenu" mat-raised-button (click)="closeSideMenu()">
						<div class="wrapTextAndSVGInSideMenu">
							<p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.CloseMenu" | translate}}</p>                     
							<svg id="icon_-_close_medium" data-name="icon - close medium" xmlns="http://www.w3.org/2000/svg" width="15.557" height="15.556" viewBox="0 0 15.557 15.556">
								<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" rx="1" transform="translate(0 1.414) rotate(-45)" fill="#066fef"/>
								<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" rx="1" transform="translate(1.414 15.556) rotate(-135)" fill="#066fef"/>
							  </svg> 
						</div>
					</button>					<!-- </mat-list-item> -->
			</mat-nav-list>
	</mat-drawer>
	<mat-drawer-content>

<div class="outerComponentColor min100vh" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
	<div class="wrapMapComponent">
		<nav class="navigationForOnboardingComponent navigationWithPositionAbsolute">
			<a [routerLink]="['/']">{{"DetailedSectionTwo.localTopNavigation.Home" | translate}}</a>
			<span> > </span>
			<a [routerLink]="['/onboarding/part-2']">{{"DetailedSectionTwo.localTopNavigation.AboutFGE" | translate}}</a>
			<span> > </span>
			<span id="sectionTitle"> {{ sectionTitle | translate}} </span>
		</nav>

<!-- <div *ngFor="let sectionItem of sections">	 -->
<!-- ------------------------- First Section ------------------------- -->
	<section class="sectionForTheDetailedPage" *ngIf="sectionId === 'section-1'">
		<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
			<a class="goBackLink" [routerLink]="['/onboarding/part-2']" fragment="customer_to_guest">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
					<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
					<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
					<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
					</g>
				</svg>				  
				{{"DetailedSectionTwo.SectionOneViewSectionPage.BackButton" | translate}}
			</a>
	
			<p class="sectionOnDetailedSection">{{"DetailedSectionTwo.SectionOneViewSectionPage.SectionNumber" | translate}}</p>
			<h2>{{ sectionTitle | translate}}.</h2>
			<p class="headingParagraph" [innerHTML]="'DetailedSectionTwo.SectionOneViewSectionPage.SectionHeader' | translate | formatHtml"></p>
			<p class="slightlyBiggerParagraph">{{"DetailedSectionTwo.SectionOneViewSectionPage.SectionDescription_One" | translate}}</p>
			<p class="slightlySmallerParagraph"><i>{{"DetailedSectionTwo.SectionOneViewSectionPage.SectionInstructions" | translate}}</i></p>
	
			<div class="wrapCompleteButtonAndText">
				<a [routerLink]="['/onboarding/part-2']" fragment="fge_vision">
					<button mat-raised-button (click)="setSecondSectionCompleted('section-1', sectionTitle)">
						{{"DetailedSectionTwo.SectionOneViewSectionPage.SectionCompleteButton" | translate}}
					</button>
				</a>
				<p>{{"DetailedSectionTwo.SectionOneViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
			</div>
		</div>
		<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
			<img src="{{'DetailedSectionTwo.SectionOneViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionTwo.SectionOneViewSectionPage.ImageAlt' | translate}}">
	
			<svg class="svgModal1Section1Part2" tabindex="0" (click)="stopAnimation('circle1Modal1Section1Part2', 'section-1', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section1Part2 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section1Part2 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			</svg>
		</div>
	</section>
<!-- ------------------------- End of the First Section ------------------------- -->

<!-- ------------------------- Second Section ------------------------- -->
	<section class="sectionForTheDetailedPage" *ngIf="sectionId === 'section-2'">
		<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
			<a class="goBackLink" [routerLink]="['/onboarding/part-2']" fragment="fge_vision">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
					<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
					<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
					<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
					</g>
				</svg>				  
				{{"DetailedSectionTwo.SectionTwoViewSectionPage.BackButton" | translate}}
			</a>
	
			<p class="sectionOnDetailedSection">{{"DetailedSectionTwo.SectionTwoViewSectionPage.SectionNumber" | translate}}</p>
			<h2>{{ sectionTitle | translate}}.</h2>
			<p class="headingParagraph" [innerHTML]="'DetailedSectionTwo.SectionTwoViewSectionPage.SectionHeader' | translate | formatHtml"></p>
			<p class="slightlyBiggerParagraph">{{"DetailedSectionTwo.SectionTwoViewSectionPage.SectionDescription_One" | translate}}</p>
	
			<div class="wrapCompleteButtonAndText">
				<a [routerLink]="['/onboarding/part-2']" fragment="fge_principles">
					<button mat-raised-button (click)="setSecondSectionCompleted('section-2', sectionTitle)">
						{{"DetailedSectionTwo.SectionTwoViewSectionPage.SectionCompleteButton" | translate}}
					</button>
				</a>
				<p>{{"DetailedSectionTwo.SectionTwoViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
			</div>
		</div>
		<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
			<img src="{{'DetailedSectionTwo.SectionTwoViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionTwo.SectionTwoViewSectionPage.ImageAlt' | translate}}">

			<svg class="svgModal1Section2Part2" tabindex="0" (click)="stopAnimation('circle1Modal2Section1Part2', 'section-2', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal2Section1Part2 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal2Section1Part2 clickable">
				  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					<circle cx="22" cy="22" r="22" stroke="none"/>
					<circle cx="22" cy="22" r="21.5" fill="none"/>
				  </g>
				</g>
				<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
			  </svg>
			  
		</div>
	</section>
<!-- ------------------------- End of the Second Section ------------------------- -->
<!-- </div> -->


<!-- ------------------------- Third Section ------------------------- -->
<section class="sectionForTheDetailedPage" id="ignoreSectionForTheDetailedPageRules" *ngIf="sectionId === 'section-3'">
	<div class="longDetailPageWrapper">
		<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
			<a class="goBackLink" [routerLink]="['/onboarding/part-2']" fragment="fge_principles">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
					<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
					<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
					<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
					</g>
				</svg>				  
				{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentOne.BackButton" | translate}}
			</a>
	
			<p class="sectionOnDetailedSection">
				{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentOne.SectionNumber" | translate}}
			</p>
			<h2>{{ sectionTitle | translate}}.</h2>
			<p class="biggerHeadingParagraph" [innerHTML]="'DetailedSectionTwo.SectionThreeViewSectionPage.ContentOne.SectionTitle' | translate | formatHtml"></p>
			<p class="headingParagraph" [innerHTML]="'DetailedSectionTwo.SectionThreeViewSectionPage.ContentOne.SectionHeader' | translate | formatHtml"></p>
			<p class="slightlyBiggerParagraph">{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentOne.SectionDescription_One" | translate}}</p>
			<!-- <p class="slightlySmallerParagraph"><i>Click the welcome mat to launch an animation</i></p> -->

		</div>
		<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
			<img src="{{'DetailedSectionTwo.SectionThreeViewSectionPage.ContentOne.ImageSource' | translate}}" alt="{{'DetailedSectionTwo.SectionThreeViewSectionPage.ContentOne.ImageAlt' | translate}}">
			<!-- <svg class="svgModal1Section3Part2" tabindex="0" (click)="stopAnimation('circle1Modal3Section1Part2', 'section-3', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal3Section1Part2 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal3Section1Part2 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			</svg> -->
		</div>
		</div>


		<div class="longDetailPageWrapper">
			<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
				<a class="goBackLink" [routerLink]="['/onboarding/part-2']" fragment="fge_principles">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
						<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
						<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
						<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
						</g>
					</svg>				  
					{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentTwo.BackButton" | translate}}
				</a>
		
				<p class="sectionOnDetailedSection">
					{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentTwo.SectionNumber" | translate}}
				</p>
				<h2>{{ sectionTitle | translate}}.</h2>
				<p class="biggerHeadingParagraph" [innerHTML]="'DetailedSectionTwo.SectionThreeViewSectionPage.ContentTwo.SectionTitle' | translate | formatHtml"></p>
				<p class="headingParagraph" [innerHTML]="'DetailedSectionTwo.SectionThreeViewSectionPage.ContentTwo.SectionHeader' | translate | formatHtml"></p>
				<p class="slightlyBiggerParagraph">{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentTwo.SectionDescription_One" | translate}}</p>	
			</div>
			<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
				<img src="{{'DetailedSectionTwo.SectionThreeViewSectionPage.ContentTwo.ImageSource' | translate}}" alt="{{'DetailedSectionTwo.SectionThreeViewSectionPage.ContentTwo.ImageAlt' | translate}}">
			</div>
		</div>


	<div class="longDetailPageWrapper">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-2']" fragment="fge_principles">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentThree.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentThree.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="biggerHeadingParagraph" [innerHTML]="'DetailedSectionTwo.SectionThreeViewSectionPage.ContentThree.SectionTitle' | translate | formatHtml"></p>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionTwo.SectionThreeViewSectionPage.ContentThree.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentThree.SectionDescription_One" | translate}}</p>	
		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-2']" fragment="we_have_got_you">
				<button mat-raised-button (click)="setSecondSectionCompleted('section-3', sectionTitle)">
					{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentThree.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionTwo.SectionThreeViewSectionPage.ContentThree.SectionInstructionUnderCompleteButton" | translate}}</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionTwo.SectionThreeViewSectionPage.ContentThree.ImageSource' | translate}}" alt="{{'DetailedSectionTwo.SectionThreeViewSectionPage.ContentThree.ImageAlt' | translate}}">
	</div>
	</div>
</section>
<!-- ------------------------- End of the Third Section ------------------------- -->

<!-- ------------------------- Fourth Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === 'section-4'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-2']" fragment="we_have_got_you">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionTwo.SectionFourViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionTwo.SectionFourViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}</h2>
		<!-- <p class="headingParagraph">We can provide Guests with things that are most<br />relevant to their journey, needs and lifestyle.<br /><br />How can we do this?</p> -->
		<p class="slightlyBiggerParagraph">{{"DetailedSectionTwo.SectionFourViewSectionPage.SectionHeader" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionTwo.SectionFourViewSectionPage.SectionDescription_One" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-2']" fragment="treating_guests_like_family">
				<button mat-raised-button (click)="setSecondSectionCompleted('section-4', sectionTitle)">
					{{"DetailedSectionTwo.SectionFourViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>
				{{"DetailedSectionTwo.SectionFourViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}
			</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionTwo.SectionFourViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionTwo.SectionFourViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section4Part2" tabindex="0" (click)="stopAnimation('circle1Modal4Section1Part2', 'section-4', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal4Section1Part2 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal4Section1Part2 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		</svg>
	</div>
</section>
<!-- ------------------------- End of the Fourth Section ------------------------- -->

<!-- ------------------------- Fifth Section ------------------------- -->

<section class="sectionForTheDetailedPage" *ngIf="sectionId === 'section-5'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-2']" fragment="treating_guests_like_family">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionTwo.SectionFiveViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionTwo.SectionFiveViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<!-- <h2>Treating Guests Like Family.</h2> -->
		<!-- <p class="headingParagraph">We can provide Guests with things that are most<br />relevant to their journey, needs and lifestyle.<br /><br />How can we do this?</p> -->
		<p class="slightlyBiggerParagraph">{{"DetailedSectionTwo.SectionFiveViewSectionPage.SectionHeader" | translate}}</p>
		<!-- <p class="slightlySmallerParagraph"><i>Click the image of the heart and the anxious brain</i></p> -->

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-2']" fragment="what_is_in_it_for_me">
				<button mat-raised-button (click)="setSecondSectionCompleted('section-5', sectionTitle)">
					{{"DetailedSectionTwo.SectionFiveViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>
				{{"DetailedSectionTwo.SectionFiveViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}
			</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionTwo.SectionFiveViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionTwo.SectionFiveViewSectionPage.ImageAlt' | translate}}">

		<!-- <svg class="svgModal2Section5Part2" tabindex="0" (click)="stopAnimation('circle2Modal2Section1Part2', 'section-4_1', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section1Part2 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section1Part2 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		</svg> -->

		<!-- <svg class="svgModal3Section5Part2" tabindex="0" (click)="stopAnimation('circle3Modal3Section1Part2', 'section-4_2', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle3Modal3Section1Part2 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle3Modal3Section1Part2 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		</svg> -->

		<svg class="svgModal1Section5Part2 {{'DetailedSectionOne.SectionNineViewSectionPage.HideIfNeeded' | translate}}" tabindex="0" (click)="stopAnimation('circle1Modal5Section1Part2', 'section-5', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal5Section1Part2 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal5Section1Part2 clickable">
			  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
			  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				<circle cx="22" cy="22" r="22" stroke="none"/>
				<circle cx="22" cy="22" r="21.5" fill="none"/>
			  </g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		  </svg>
		  
	</div>
</section>
<!-- ------------------------- End of the Fifth Section ------------------------- -->

<!-- ------------------------- Sixth Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === 'section-6'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-2']" fragment="what_is_in_it_for_me">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionTwo.SectionSixViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">
			{{"DetailedSectionTwo.SectionSixViewSectionPage.SectionNumber" | translate}}
		</p>
		<h2>{{ sectionTitle | translate}}</h2>
		<!-- <p class="headingParagraph">We can provide Guests with things that are most<br />relevant to their journey, needs and lifestyle.<br /><br />How can we do this?</p> -->
		<p class="slightlyBiggerParagraph">
			{{"DetailedSectionTwo.SectionSixViewSectionPage.SectionHeader" | translate}}
		</p>
		<p class="slightlySmallerParagraph"><i>
			{{"DetailedSectionTwo.SectionSixViewSectionPage.SectionMainContent" | translate}}
		</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/']">
				<button mat-raised-button (click)="setSecondSectionCompleted('section-6', sectionTitle)">
					{{"DetailedSectionTwo.SectionSixViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>
				{{"DetailedSectionTwo.SectionSixViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}
			</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionTwo.SectionSixViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionTwo.SectionSixViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section6Part2" tabindex="0" (click)="stopAnimation('circle1Modal6Section1Part2', 'section-6', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal6Section1Part2 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal6Section1Part2 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		</svg>

		<svg class="svgModal2Section6Part2" tabindex="0" (click)="stopAnimation('circle2Modal6Section1Part2', 'section-7', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal6Section1Part2 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal6Section1Part2 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		</svg>

		<svg class="svgModal3Section6Part2" tabindex="0" (click)="stopAnimation('circle3Modal6Section1Part2', 'section-8', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle3Modal6Section1Part2 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle3Modal6Section1Part2 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		</svg>
	</div>
</section>
<!-- ------------------------- End of the Sixth Section ------------------------- -->


	</div>
		<!-- Footer -->
		<footer class="footerForMapComponent">
			<div class="wrapfootercomponents">
			<section>
					<p>
						<span class="{{'DetailedSectionOne.AnimatedMapFooter.longWordsInTheDetailSectionFooterHolder' | translate}}" >
							<span class="blueBoldText">{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionTwo_Pre" | translate}}</span> 
							<span class="solveSpacingViewSection {{'DetailedSectionOne.AnimatedMapFooter.longWordsInTheDetailSectionFooter' | translate}}">{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionTwo" | translate}}</span>
						</span>
						
						<!-- <br class="showOnMobileOnly" />  -->
						<span class="dontShowOnMobile">|</span> <span class="lightBlueText longWordsShallNotPass {{'DetailedSectionTwo.localTopNavigation.longWordsShallNotPassGB2' | translate}}"><b>{{ sectionTitle | translate}}</b></span>
					</p>
			</section>

			<div class="containerInMap" >
				<ng-container *ngFor="let secondStatus of secondSectionStatus; let i = index; let last = last">
                    <a (click)="toggleMenu()" class="dots" [ngClass]="{ 'outerBorder': secondStatus }">
                      <div class="mini-dot" [ngClass]="{ 'activeDot': secondStatus }"></div>
                    </a>
                    <span *ngIf="!last"></span>
                </ng-container>
			</div>
			<section>
				<span class="viewSections">{{"DetailedSectionOne.AnimatedMapFooter.RightSection" | translate}}</span>
				<button (click)="hideConfirmation(); closeToastOnClick(); drawer.toggle()" mat-icon-button class="menu-icon" aria-label="Example icon-button with menu icon">
					<!-- <mat-icon>menu</mat-icon> -->
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                        <g id="icon_-_hamburger_menu_-_default" data-name="icon - hamburger menu - default" transform="translate(6)">
                          <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="18" rx="1" transform="translate(0 2) rotate(-90)" fill="#066FEF"/>
                          <rect id="Rectangle_169" data-name="Rectangle 169" width="2" height="18" rx="1" transform="translate(0 9) rotate(-90)" fill="#066FEF"/>
                          <rect id="Rectangle_170" data-name="Rectangle 170" width="2" height="18" rx="1" transform="translate(0 16) rotate(-90)" fill="#066FEF"/>
                        </g>
                        <g id="icon_-_hamburger_menu_-_default-2" data-name="icon - hamburger menu - default">
                          <rect id="Rectangle_11-2" data-name="Rectangle 11" width="2" height="3" rx="1" transform="translate(0 2) rotate(-90)" fill="#0f0f0f"/>
                          <rect id="Rectangle_169-2" data-name="Rectangle 169" width="2" height="3" rx="1" transform="translate(0 9) rotate(-90)" fill="#0f0f0f"/>
                          <rect id="Rectangle_170-2" data-name="Rectangle 170" width="2" height="3" rx="1" transform="translate(0 16) rotate(-90)" fill="#0f0f0f"/>
                        </g>
                      </svg>
				</button>
			</section>
		</div>
	</footer>
</div>

</mat-drawer-content>
</mat-drawer-container>