import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
  })
  export class HelperService {
    private tokenKey = 'token';
    private marketKey = 'market';
    private hostKey = 'host';
    private isFirstLoadKey = 'ft';
  
    setToken(token: string) {
      this.set(this.tokenKey, token);
      this.remove(this.isFirstLoadKey);
    }
  
    getToken() {
      return this.get(this.tokenKey);
    }
  
    setMarket(market: string) {
      this.set(this.marketKey, market);
    }
  
    getMarket() {
      const currentHost = window.location.host;
      const storedHost = this.get(this.hostKey);
      var market = this.get(this.marketKey);

      // Domain may have changed
      if (currentHost !== storedHost || !market || market === '') {
        let data = new Map<string, string>();

        // Send a request to get all the headers
        var req = new XMLHttpRequest();
        req.open('GET', `${window.location.origin}/${environment.suffixUrl}`, false);
        req.send(null);  
        var headers = req.getAllResponseHeaders().toLowerCase();
        var aHeaders = headers.split('\r\n');
  
        for (var i = 0; i < aHeaders.length; i++) {
          var thisItem = aHeaders[i];
          var key = thisItem.substring(0, thisItem.indexOf(':'));
          var value = thisItem.substring(thisItem.indexOf(':') +  1);
          data.set(key, value.trim());
        }
  
        var newMarket = data.get("x-market") || "CA";

        if (newMarket !== market) {
          // should reset the user so we cause a check and possibly update their language
          this.set(this.isFirstLoadKey, "true");
        }
        this.set(this.marketKey, newMarket);
        this.set(this.hostKey, currentHost);
      }

      return market;
    }

    isFirstLoad() : boolean {
      var item = localStorage.getItem(this.isFirstLoadKey);

      if (item == null) {
        item = "true";
      }
      
      this.set(this.isFirstLoadKey, "false");

      return JSON.parse(item);
    }

    removeToken() {
      this.remove(this.tokenKey);
    }

    removeMarket() {
      this.remove(this.marketKey);
    }

    private get(key: string) {
      return localStorage.getItem(key);
    }

    private remove(key: string) {
      localStorage.removeItem(key);
    }

    private set(key: string, value: string) {
      localStorage.setItem(key, value);
    }
  }