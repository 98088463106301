import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CardService { 
  constructor(private http: HttpClient) {}

  getCards(): Observable<any> {    
    return this.http.get<any>(`${environment.apiUrl}/resource/categories/`);
  }  

  getCardsWithParam(category:any): Observable<any> {
    const params = new HttpParams().set('Take', category);

    return this.http.get<any>(`${environment.apiUrl}/resource/categories`, { params: params });
  }

  getResourceContentTypes(): Observable<any> {
     return this.http.get<any>(`${environment.apiUrl}/metadata/resourcecontenttypes`);
  }

  getResourceTypes(): Observable<any> {
     return this.http.get<any>(`${environment.apiUrl}/metadata/resourcetypes`);
  }

  getBookmarked(): Observable<any>{
    const params = new HttpParams().set('take', 10);

    return this.http.get<any>(`${environment.apiUrl}/resource/bookmarked`, { params: params });
  }

  getBookmarkedWithParam(param: any): Observable<any>{
    const params = new HttpParams().set('ContentTypeId', param);

    return this.http.get<any>(`${environment.apiUrl}/resource/bookmarked`, { params: params });
  }

  getBookmarkedWithCategory(contentType: any, category: any): Observable<any>{   
    return this.http.get<any>(`${environment.apiUrl}/resource/bookmarked?CategoryId=${category}&ContentTypeId=${contentType}`);
  }

  getBookmarkedAllParam(contentType: any, category: any, skip: any, take:any): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}/resource/bookmarked?CategoryId=${category}&ContentTypeId=${contentType}&Skip=${skip}&Take=${take}`);
  }
  
}