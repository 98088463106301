import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../services/helper.service';
import { RouteService } from '../services/route.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent {
  constructor(private route: ActivatedRoute, private helperService: HelperService, private routeService: RouteService) {}

  ngOnInit(): void {
    if (environment.useExternalAuth) {
      this.route.params.subscribe((params) => {
        this.helperService.setToken(params['token']);
        this.routeService.home();
      });
    }
  }
}