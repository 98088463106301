import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        triggerAuthorizationResultEvent: true,
        postLoginRoute: '/',
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/error/unauthorized',
        logLevel: LogLevel.Debug,
        historyCleanupOff: true,
        authority: environment.issuer,
        redirectUrl: `${window.location.origin}/callback`,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.clientId,
        scope: environment.scopes,
        responseType: 'code',
        silentRenew: false,
        useRefreshToken: false
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}