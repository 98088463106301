<div class="fullHeight">
    <nav class="navigationWithPositionAbsolute">
        <a class="niceLineForFooterNav" [routerLink]="['/']">Home </a>
        <span> > </span>
        <span> Privacy Policy </span>
    </nav>
    
    <div class="contentHolder">
        <h2>Privacy Policy</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate repellendus quisquam ab corporis, ut, odio libero iusto, optio nihil maxime placeat. Dicta ullam dolores autem tempora exercitationem inventore? Ut, repellendus.</p>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eius, et, earum officia fuga quaerat voluptatibus eum dolore itaque veniam iste nihil, impedit nemo. Dicta dolor, aliquam non vel laborum facilis.</p>
    </div>
</div>
