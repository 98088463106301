<div class="contentWrapper">
    <div class="all-categories-block">
        <mat-card class="category-card">
            <a mat-raised-button class="linkInTheCategoryCard" routerLink="/support-guide/details">
            <mat-card-header>
                <mat-card-title-group>
                    <mat-card-title>Mobile Service</mat-card-title>
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Etiam nisi at ligula dignissim, id laoreet augue faucibus.
              </mat-card-content>
            </a>
          </mat-card>
          <mat-card class="category-card">
            <a mat-raised-button class="linkInTheCategoryCard" routerLink="/support-guide/details">
            <mat-card-header>
                <mat-card-title-group>
                    <mat-card-title>Mobile Service</mat-card-title>
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Etiam nisi at ligula dignissim, id laoreet augue faucibus.
            </mat-card-content>
            </a>
        </mat-card>         
    </div>
</div>

