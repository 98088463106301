<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
    <nav class="navigationWithPositionAbsolute">
        <a class="niceLineForFooterNav" [routerLink]="['/']">{{'TermsAndConditions.Home' | translate}} </a>
        <span> > </span>
        <span> {{'TermsAndConditions.TermsAndConditions' | translate}} </span>
    </nav>
    
    <div class="contentHolder">
        <h2 class="mb-30">{{'TermsAndConditions.TermsAndConditionsTitle' | translate}}</h2>
        <p class="main-text">{{'TermsAndConditions.UseOfServiceTitle' | translate}}</p>
        <p [innerHTML]="'TermsAndConditions.UseOfServiceP1' | translate | formatHtml"></p>
        <p>{{'TermsAndConditions.UseOfServiceP2' | translate}}</p>
        <br />
        <p class="main-text">{{'TermsAndConditions.AccessingServices' | translate}}</p>
        <p>{{'TermsAndConditions.AccessingServicesP1' | translate}}</p>
        <br />
        <p class="main-text">{{'TermsAndConditions.Account' | translate}}</p>
        <p>{{'TermsAndConditions.AccountP1' | translate}}</p>
        <br />
        <p class="main-text">{{'TermsAndConditions.ProtectedContent' | translate}}</p>
        <p>{{'TermsAndConditions.ProtectedContentP1' | translate}}</p>
        <p>{{'TermsAndConditions.ProtectedContentP2' | translate}}</p>
        <p>{{'TermsAndConditions.ProtectedContentP3' | translate}}</p>
        <br />
        <p class="main-text">{{'TermsAndConditions.LicenceToUse' | translate}}</p>
        <p>{{'TermsAndConditions.LicenceToUseP1' | translate}}</p>
        <p>{{'TermsAndConditions.LicenceToUseP2' | translate}}</p>
        <p>{{'TermsAndConditions.LicenceToUseP3' | translate}}</p>
        <br />
        <p class="main-text">{{'TermsAndConditions.Restriction' | translate}}</p>
        <p>{{'TermsAndConditions.RestrictionP1' | translate}}</p>
        <p>{{'TermsAndConditions.RestrictionP2' | translate}}</p>
        <ul>
            <li>{{'TermsAndConditions.RestrictionLi1' | translate}}</li>
            <li>{{'TermsAndConditions.RestrictionLi2' | translate}}</li>
            <li>{{'TermsAndConditions.RestrictionLi3' | translate}}</li>
            <li>{{'TermsAndConditions.RestrictionLi4' | translate}}</li>
            <li>{{'TermsAndConditions.RestrictionLi5' | translate}}</li>
            <li>{{'TermsAndConditions.RestrictionLi6' | translate}}</li>
            <li>{{'TermsAndConditions.RestrictionLi7' | translate}}</li>
        </ul>
        <p>{{'TermsAndConditions.RestrictionP3' | translate}}</p>
        <br />
        <p class="main-text">{{'TermsAndConditions.Disclaimers' | translate}}</p>
        <ul>
            <li class="main-text">{{'TermsAndConditions.DisclaimersLi1' | translate}}</li>
        </ul>
        <p>{{'TermsAndConditions.DisclaimersP1' | translate}}</p>
        <p>{{'TermsAndConditions.DisclaimersP2' | translate}}</p>
        <ul>
            <li class="main-text">{{'TermsAndConditions.Downloads' | translate}}</li>
        </ul>
        <p>{{'TermsAndConditions.DownloadsP1' | translate}}</p>
        <p>{{'TermsAndConditions.DownloadsP2' | translate}}</p>
        <p>{{'TermsAndConditions.DownloadsP3' | translate}}</p>
        <p>{{'TermsAndConditions.DownloadsP4' | translate}}</p>
        <ul>
            <li class="main-text">{{'TermsAndConditions.DownloadsLi' | translate}}</li>
        </ul>
        <p class="main-text">{{'TermsAndConditions.ServicesAndContent' | translate}}</p>
        <p class="main-text">{{'TermsAndConditions.PermittedByLaw' | translate}}</p>
        <br />
        <p class="main-text">{{'TermsAndConditions.Indemnity' | translate}}</p>
        <p>{{'TermsAndConditions.IndemnityP1' | translate}}</p>
        <br />  
        <p class="main-text">{{'TermsAndConditions.Termination' | translate}}</p>
        <p>{{'TermsAndConditions.TerminationP1' | translate}}</p>
        <p>{{'TermsAndConditions.TerminationP2' | translate}}</p>
        <br />  
        <p class="main-text">{{'TermsAndConditions.Jurisdiction' | translate}}</p>
        <p>{{'TermsAndConditions.JurisdictionP1' | translate}}</p>
        <br />  
        <p class="main-text">{{'TermsAndConditions.General' | translate}}</p>
        <p>{{'TermsAndConditions.GeneralP1' | translate}}</p>
        <p>{{'TermsAndConditions.GeneralP2' | translate}}</p>
        <p>{{'TermsAndConditions.GeneralP3' | translate}}</p>    
    </div>
</div>
