import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LanguageService } from '../APIs/allLanguages.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { SharedModule } from '../shared.module';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../APIs/profile.service';
import { CardService } from '../APIs/card.service';


@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, MatInputModule, FormsModule, CommonModule, SharedModule]
})

@Injectable({
  providedIn: 'root',
})

export class OnboardingModalComponent {
  constructor(public onboardingDialog: MatDialog, private translateService: TranslateService) {}  

  openWelcomeDialog() {    
    const dialogRef = this.onboardingDialog.open(DialogContentExampleDialog, { disableClose: false });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

  @Component({
    selector: 'app-onboarding-modal',
    templateUrl: './onboarding-modal.component-dialog.html',
    styleUrls: ['./onboarding-modal.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, MatInputModule, FormsModule, MatSelectModule, ReactiveFormsModule, CommonModule, SharedModule]
  })
  
  export class DialogContentExampleDialog implements OnInit {
    defaultLanguage = new FormControl(localStorage.getItem('siteLang'));  
    languages: any[] = [];
    marketLanguages: any[] = [];
    siteLangModal: string = '';
    response: any;
    initialCards: any[] = []; 
    apiStatus: string = 'pending';
    totalCategories: number = 0;    
  
    constructor(public onboardingLangDialog: MatDialog, private languageService: LanguageService, private translateService: TranslateService, private profileService: ProfileService, private cardService: CardService) {}
  
    ngOnInit() {
      this.profileService.createOrUpdate().subscribe((data: any) => {
        this.response = data;        
      });

      if (this.defaultLanguage.value == null) {
        this.profileService.getProfile().subscribe((data: any) =>{     
          this.defaultLanguage = new FormControl(data.language);
        });
      }
      this.languageService.getLanguages().subscribe((data: any) => {
        this.languages = data.languages;
        this.languages.forEach( (item, index) => {
          if(item.market.toLowerCase() === localStorage.getItem("market")?.toLowerCase()) {
           this.marketLanguages.push(this.languages[index]);                      
          }       
          this.siteLangModal = localStorage.getItem('siteLang') || this.marketLanguages[0]?.code;         
              
        });
      });
    }
  
    openMainModal() {
      const selectedLanguage = this.languages.find((language) => language.code === this.defaultLanguage.value);
       if (selectedLanguage) {
         this.languageService.updateUserLanguage(selectedLanguage.code).subscribe((response) => {});
         this.translateService.use(selectedLanguage.code);
         localStorage.setItem('siteLang', selectedLanguage.code);
      }
  
      this.onboardingLangDialog.open(DialogContentMainDialog);
    }

    doNotOpenMainModal() {
      const selectedLanguage = this.languages.find((language) => language.code === this.defaultLanguage.value);
      if (selectedLanguage) {
        this.languageService.updateUserLanguage(selectedLanguage.code).subscribe((response) => {
          this.cardService.getCards().subscribe((data) => {
            const typedData = data as { items: any[] };
            this.initialCards = typedData.items;      
            this.totalCategories = data.total;
            sessionStorage.setItem("CategoriesNumber", data.total);
            this.apiStatus = 'success';
          });
        });
        this.translateService.use(selectedLanguage.code);
        localStorage.setItem('siteLang', selectedLanguage.code);
      }
 
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
    
  }
  

  @Component({
    selector: 'app-onboarding-modal',
    templateUrl: './onboarding-modal.component-main.html',
    styleUrls: ['./onboarding-modal.component.scss'],
    standalone: true,
    imports: [DialogContentExampleDialog, MatDialogModule, MatButtonModule, MatInputModule, FormsModule, MatSelectModule, ReactiveFormsModule, CommonModule, SharedModule]
  })
  export class DialogContentMainDialog {
    constructor(private router: Router, public onboardingLangDialog: MatDialog , private tagService: GA4Service, private translateService: TranslateService) {} // Add onboardingLangDialog here
  
    public visible1 = true;
    public visible2 = false;
    public visible3 = false;
      
    goNextStep(section: any) {
      this.tagService.onboardClickPush(section);
      if (section == 1) {
        this.visible1 = false;
        this.visible2 = true;
      } else if (section == 2) {
        this.visible2 = false;
        this.visible3 = true;
      } else if (section == 3) {       
        this.router.navigate(['/']);
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }
    }
  
    openMainModal() {
      const dialogRef = this.onboardingLangDialog.open(DialogContentMainDialog, { disableClose: false });
 
      dialogRef.afterClosed().subscribe(result => {
        window.location.reload();
      });
    }
  }
  