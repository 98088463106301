<div class="lang-wrapper">
  <h2 mat-dialog-title class="select-lang-title">Choose your language</h2>
  <mat-dialog-content class="mat-typography">
    <p class="select-lang-text">This will be the default language every time you access FGE GO. You can update your preferred language on your profile page anytime.</p>
  </mat-dialog-content>
  <mat-dialog-actions class="main-modal-btn-line">
    <mat-form-field>
     
      <mat-select [formControl]="defaultLanguage">     
        <mat-option *ngFor="let language of marketLanguages" selected="siteLangModal==='language.code'" [value]="language.code">{{ language.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button *ngIf="response && response.status !== 202" mat-raised-button mat-dialog-close (click)="openMainModal()" class="langBtn" >Submit</button>
    <button *ngIf="response && response.status === 202" mat-raised-button mat-dialog-close (click)="doNotOpenMainModal()" class="langBtn" >Submit</button>
  </mat-dialog-actions>
</div>


