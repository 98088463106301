<div class="outerComponentColor makePage100vh">
    <div class="wrapMapComponent">
        <nav class="navigationForOnboardingComponent navigationWithPositionAbsolute" id="scrollToElement">
            <a routerLink='/'>Home </a>
            <span> > </span>
            <span> About FGE</span>
        </nav>
        <section class="wrapImages">
            <img class="imgSection1" src="./assets/circle.png" alt="">
            <img class="imgSection2" src="./assets/circle.png" alt="">
            <img class="imgSection3" src="./assets/circle.png" alt="">
            <img class="imgSection4" src="./assets/circle.png" alt="">
            <img class="imgSection5" src="./assets/circle.png" alt="">
            <img class="imgSection6" src="./assets/circle.png" alt="">
            <img class="imgSection7" src="./assets/circle.png" alt="">
            <img class="imgSection8" src="./assets/circle.png" alt="">
            <img class="imgSection9" src="./assets/circle.png" alt="">
            <img class="imgSection10" src="./assets/circle.png" alt="">
            <img class="imgSection11" src="./assets/circle.png" alt="">
            <img class="imgSection12" src="./assets/circle.png" alt="">
            <img class="imgSection13" src="./assets/circle.png" alt="">
            <img class="imgSection14" src="./assets/circle.png" alt="">
            <img class="imgSection15" src="./assets/circle.png" alt="">
            <img class="imgSection16" src="./assets/circle.png" alt="">
            <img class="imgSection17" src="./assets/circle.png" alt="">
        </section>

        <section class="wrapContent">
            <main>
                <h1>Welcome to FGE</h1>
                <p>
                    Learn how the Ford Guest Experience is designed to
                    empower your client experience and help you to take your
                    sales game to the next level by decoding the changes in
                    the communities, mindsets and opportunities around you.
                </p>
                <p>
                    To capture progress, make sure to click ‘complete’ for
                    each section that you visit.
                </p>
        
                <div class="wrapButtons">
                    <a routerLink='/onboarding/part-1'><button mat-raised-button>Begin</button></a>
                    <button mat-raised-button (click)="downloadGA4Push()">View Worksheet</button>
                </div>
            </main>
        </section>
    </div>
</div>
